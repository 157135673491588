import React, { useState, useEffect } from "react";

// MUI
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';

//API
import { fetchProductById, verifyIfAllergen } from '../../../../api/stockAPI'

// Style
import '../popup-infos/PopupInfos.css';

export default function PopupRecipeInfos({ open, onClose, ingredients, title, description, imageUrl }) {


const [ingredientDetails, setIngredientDetails] = useState([]);
const [error, setError] = useState(null);

useEffect(() => {
    const fetchIngredientsDetails = async () => {
        try {
            const details = await Promise.all(
                ingredients.map(async (ingredient) => {
                    const detail = await fetchProductById(ingredient.id);
                    const isAllergen = await verifyIfAllergen(ingredient.id);
                    return { ...detail, isAllergen };
                })
            );
            setIngredientDetails(details);
        } catch (error) {
            setError(error);
        }
    };

    if (ingredients && ingredients.length > 0) {
        fetchIngredientsDetails();
    }
}, [ingredients]);

const getColorAndEmoji = (ingredientDetail) => {
    if (ingredientDetail?.isAllergen?.exists) {
        return { sx: { bgcolor: "#FFD1D1", color: "#3B3636" }, emoji: "❗️" };
    }
    switch (ingredientDetail.label) {
        case "bio":
            return { sx: { bgcolor: "#CFFFCE", color: "#3B3636" }, emoji: "🌿" };
        case "national":
            return { sx: { bgcolor: "#D7EAFF", color: "#3B3636" }, emoji: "🇫🇷" };
        case "local":
            return { sx: { bgcolor: "#D2F1F3", color: "#3B3636" }, emoji: "🏡" };
        default:
            return { sx: {}, emoji: "" };
    }
};

if (error) {
    return <div>Error loading ingredients: {error.message}</div>;
}

return (
    <>
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    
                    <div className="infos-modal">
                    <div className="infos-modal-action">
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Fermer
                                </button>
                            </div>
                        <div className="edit-modal-title">
                            <h2>{title}</h2>
                        </div>
                        {imageUrl && ( // Affichage conditionnel de l'image
                        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                            <img
                                src={imageUrl}
                                alt="Recette"
                                style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: "300px",
                                    borderRadius: "8px",
                                }}
                            />
                        </div>
                    )}
                        <div className="infos-modal-descriptions">
                            <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                                Description
                            </Typography>
                            <p>{description}</p>
                        </div>
                        <div className="infos-modal-content">
                            <div className="items-list">

                                <Typography level="body-sm" sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                                    Ingrédients
                                </Typography>

                                <div className="ingredient-list">
                                    {ingredients.map((ingredient, index) => (
                                        <div key={index} className="ingredient-item">
                                            <Chip sx={getColorAndEmoji(ingredient).sx}>
                                                {ingredient.name} {getColorAndEmoji(ingredient).emoji}
                                            </Chip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="ingredients-legend">
                                <Chip sx={{ bgcolor: "#CFFFCE", color: "#3B3636" }}>
                                    Bio 🌿
                                </Chip>
                                <Chip sx={{ bgcolor: "#D2F1F3", color: "#3B3636" }}>
                                    Local 🏡
                                </Chip>
                                <Chip sx={{ bgcolor: "#D7EAFF", color: "#3B3636" }}>
                                    Français 🇫🇷
                                </Chip>
                                <Chip sx={{ bgcolor: "#FFD1D1", color: "#3B3636" }}>
                                    Allergène ❗️
                                </Chip>
                            </div>
                            
                        </div>
                    </div>
                </>
            )}
        </>
    </>
);


}