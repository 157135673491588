import { useEffect, useState } from "react";

// API
import { fetchParticipantByUserId, fetchGroupOrdersParticipants } from "../../api/groupOrdersParticipantsAPI";
import { fetchGroupOrderById } from "../../api/groupOrdersAPI";
import { fetchOrderById } from "../../api/ordersAPI";
import { getItemsByOrderId } from "../../api/orderItemsAPI";
import { fetchRecipeById } from "../../api/recipesAPI";

// MUI
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function GroupOrdersTracking() {
  const [participant, setParticipant] = useState({});
  const [groupOrderInfos, setGroupOrderInfos] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Nouveau état pour le chargement

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

  useEffect(() => {
    const getParticipant = async () => {
      setIsLoading(true); // Début du chargement
      try {
        console.log("Début de récupération des données du participant");

        const userId = JSON.parse(localStorage.getItem("user")).id;

        // Récupérer les informations du participant
        const participantData = await fetchParticipantByUserId(userId);
        console.log("Participant récupéré :", participantData);
        setParticipant(participantData);

        // Récupérer les informations de la commande groupée
        const groupOrderId = participantData.group_order_id;
        const fetchedGroupOrder = await fetchGroupOrderById(groupOrderId);
        console.log("Commande groupée récupérée :", fetchedGroupOrder);
        setGroupOrderInfos(fetchedGroupOrder);

        // Récupérer les participants
        const participants = await fetchGroupOrdersParticipants(groupOrderId);
        console.log("Participants récupérés :", participants);

        if (participants && participants.length > 0) {
          const participantOrderIds = participants.map((participant) => participant.order_id);
          console.log("ID des commandes des participants :", participantOrderIds);

          // Calculer le total des commandes
          const orderPromises = participantOrderIds.map((id) =>
            fetchOrderById(id).then((order) => order.price || 0)
          );
          const orderPrices = await Promise.all(orderPromises);
          const total = orderPrices.reduce((sum, price) => sum + price, 0);
          console.log("Montant total calculé avec fetchOrderById :", total);
          setTotalPrice(total);
        } else {
          console.warn("Aucun participant trouvé pour cette commande groupée.");
        }

        // Récupérer les items et recettes de la commande principale de l'utilisateur
        const orderData = await getItemsByOrderId(participantData.order_id);
        console.log("Items de commande récupérés :", orderData);

        const recipePromises = orderData.map((item) =>
          fetchRecipeById(item.recipeId).then((recipe) => ({
            ...recipe,
            quantity: item.quantity,
          }))
        );
        const recipesData = await Promise.all(recipePromises);
        console.log("Recettes récupérées :", recipesData);
        setRecipes(recipesData);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de la commande groupée :",
          error
        );
      } finally {
        setIsLoading(false); // Fin du chargement
      }
    };

    getParticipant();
  }, []);

  const progress = groupOrderInfos.max_participants
    ? (groupOrderInfos.current_participants / groupOrderInfos.max_participants) * 100
    : 0;

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  if (isLoading) {
    return (
      <div className="loader" style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <CircularProgress color="success" /> {/* Loader circulaire */}
      </div>
    );
  }

  return (
    <div className="orders-table" style={{ marginBottom: isMobile ? 2 : 0 }}>
      <div className="orders-table-title flex-center">
        <h1 style={{ margin: "2rem 0" }}>Votre commande groupée</h1>
      </div>
      <div style={{ width: isMobile ? "90%" : isTablet ? "80%" : "60%", margin: "auto" }}>
        <Stack spacing={2} sx={{ flex: 1, width: "100%" }}>
          <div style={{ marginBottom: "1rem" }}>
            <LinearProgress variant="determinate" value={progress} color="success" />
            <Typography level="h3" fontSize="xs" sx={{ mb: 3, textAlign: "center", marginTop: "1rem" }}>
              Progression : {Math.round(progress)}%
            </Typography>
          </div>
          <Typography level="body" fontSize="sm" sx={{ marginTop: 2, textAlign: "justify" }}>
            Vous avez passé une commande groupée le <strong>{formatDate(participant.createdAt)}</strong>.
            <br />
            Code postal : <strong>{groupOrderInfos.postal_code}</strong>
            <br />
            Montant total de la commande groupée : <strong>{(totalPrice / 100).toFixed(2)} €</strong>
            <br />
            Nombre de participants : {groupOrderInfos.current_participants} / {groupOrderInfos.max_participants}
            <br />
            <br />
            Cette commande sera validée dès que le montant atteindra 250 € ou que {groupOrderInfos.max_participants} participants auront rejoint.
            <br />
            <br />
            L'équipe Mamossa vous informera de l'évolution de votre commande par e-mail 💌
            <br />
            <br />
            Contactez à tout moment le +33604534730, hello@mamossa.com ou le module de chat en bas a gauche de l'écran pour du support au sujet de votre commande.
          </Typography>
        </Stack>
        <Stack spacing={2} sx={{ flex: 1, width: "100%", mt: 2 }}>
          <Card variant="soft" color={progress >= 100 ? "success" : "warning"} sx={{ padding: "1rem", marginBottom: "2rem" }}>
            <CardContent>
              <Typography level="title-md" sx={{ mb: 2 }}>Récapitulatif de votre commande</Typography>
              {recipes.length > 0 ? (
                recipes.map((recipe, index) => (
                  <CardContent key={index}>
                    <Typography level="title-h3" fontSize="sm" sx={{ fontWeight: "600" }}>{recipe.name}</Typography>
                    <Typography fontSize="xs" sx={{ fontWeight: "600" }}>
                      Prix : {(recipe.price * recipe.quantity).toFixed(2)} € — Quantité : {recipe.quantity}
                    </Typography>
                    <Typography fontSize="xs">{recipe.description}</Typography>
                  </CardContent>
                ))
              ) : (
                <Typography fontSize="xs" sx={{ textAlign: "center" }}>Aucune recette disponible pour cette commande.</Typography>
              )}
            </CardContent>
          </Card>
        </Stack>
      </div>
    </div>
  );
}
