import React, { useState, useEffect } from "react";
import { CiEdit } from "react-icons/ci";
import NutriA from '../../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../../assets/nutri-score/NutriscoreE.png';

function RecipesHeader({ recipe, isEditing, onEditClick, nutriFacts }) {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setCurrentUser(user.id);
        }
    }, []);

    const isOwner = currentUser === recipe?.user_id;

    const getNutriScoreImage = (nutriscore) => {
        switch (nutriscore) {
            case 'A':
                return <img src={NutriA} alt="NutriA" />;
            case 'B':
                return <img src={NutriB} alt="NutriB" />;
            case 'C':
                return <img src={NutriC} alt="NutriC" />;
            case 'D':
                return <img src={NutriD} alt="NutriD" />;
            case 'E':
                return <img src={NutriE} alt="NutriE" />;
            default:
                return <p>NutriScore indisponible</p>;
        }
    };

    return (
        <div className="recipes-details-name">
            <div className="recipes-details-nutriscore">
                {getNutriScoreImage(nutriFacts?.nutriscore)}
            </div>
            <div>
            {isOwner && (
                    <button onClick={onEditClick} className="edit-btn">
                        <CiEdit className="edit-icon" size={15} />
                    </button>
                )}
                <h1>{recipe.name}</h1>
            </div>
        </div>
    );
}

export default RecipesHeader;
