import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSubscription } from "../../api/subsciptionAPI";
import Button from '@mui/joy/Button';
import BackButton from "../elements/backbutton/BackButton";
import "../../views/client-dashboard/ClientDashboard.css"; 


function ClientSubscription() {
    const [subscriptionResponse, setSubscriptionResponse] = useState(null);
    const [hasFetchedSubscription, setHasFetchedSubscription] = useState(false); // Drapeau pour éviter les appels répétés
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (userInfo && userInfo.id && !hasFetchedSubscription) { // Exécuter seulement si non déjà récupéré
            const fetchUserSubscription = async () => {
                try {
                    const response = await fetchSubscription(userInfo.id);
                    setSubscriptionResponse(response);
                    setHasFetchedSubscription(true); // Marquer comme récupéré
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'abonnement :", error);
                }
            };

            fetchUserSubscription();
        }
    }, [userInfo, hasFetchedSubscription]);

    if (!userInfo || !userInfo.id) {
        return (
            <section className='client-subscription'>
                <div className='flex-center'>
                    <h2>Chargement des informations utilisateur...</h2>
                </div>
            </section>
        );
    }

    // Si aucun abonnement n'a été trouvé, afficher la table de prix Stripe

    if (subscriptionResponse?.status === 200 && !subscriptionResponse.data) {
        return (
            <section className='client-subscription' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div className='flex-center'>
                
                    <div className="flex justify-between items-center w-full">
                       
                        <h2 className="client-subscription-title">
                            Explorez de Nouvelles Saveurs en toute simplicité avec le Menu-Maker de Mamossa
                        </h2>
                    </div>
                </div>
                <div className='flex-center'>
                    <p className="client-subscription-description">
                        Découvrez notre catalogue de recettes et notre concepteur de Menu.
                        Calculez les coûts, apports nutritionnels des recettes, prévoyez les listes de courses de vos menus,
                        découvrez de nouvelles recettes et partagez vos créations !<br /><br />
                        Abonnez-vous à une des formules pour simplifier votre cuisine avec le Menu Maker de Mamossa. <br /><br />
                        Libérez votre créativité culinaire dès maintenant !
                    </p>
                </div>

                {/* Intégration de la table de prix Stripe */}
                <div className='stripe-pricing'>
                    <stripe-pricing-table
                        pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
                        publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
                        client-reference-id={userInfo.id}
                        customer-email={userInfo.email}
                    />
                </div>
                <div className="flex-center">
                    {/* <Button onClick={() => navigate("/account")}>
                        Retour
                    </Button> */}
                    <BackButton onClick={() => navigate("/account")} className="mr-4">Retour</BackButton>
                </div>
            </section>
        );
    }

    if (subscriptionResponse?.status === 200 && subscriptionResponse.data) {
        const subscriptionInfos = subscriptionResponse.data;

        return (
            <section className='client-manage-subscription'>
                <div className='flex-center'>
                <BackButton onClick={() => navigate("/account")} title="Retour" />
                    <div>
                        
                        <h2>Votre abonnement MenuMaker</h2>
                    </div>
                </div>
                <div className="flex-center subscription-info">
                    <p>
                        Vous êtes membre premium Mamossa depuis le {new Date(subscriptionInfos.createdAt).toLocaleDateString()}.
                    </p>
                    <p>
                        Pour le moment, il n'est pas possible de gérer son abonnement autrement que manuellement.
                    </p>
                    <p>
                        Veuillez contacter le +33 6 04 53 47 30 ou bien envoyer un mail à <a href="mailto:hello@mamossa.com">hello@mamossa.com</a> pour upgrader ou supprimer votre plan.
                    </p>
                    <p>Nous travaillons sur l'amélioration de cette fonctionnalité.</p>
                </div>

                <BackButton onClick={() => navigate("/account")} title="Retour" />
                {/* 
                <div className='flex-center'>
                    <a href="https://billing.stripe.com/p/login/14k18a8FB82f0wM6oo" target="_blank" rel="noopener noreferrer">
                        <button className='btn-full'>Gérer mon abonnement</button>
                    </a>
                </div>
                */}
            </section>
        );
    }

    return null;
}

export default ClientSubscription;