import { useState } from 'react';
import RecipeCard from '../../../recipes/RecipeCard';
import './PopupMenu.css';


const PopupMenu = ({ open, onClose, onRecipeSelect, recipes = [], onAddRecipesToMenu, existingMenuRecipes = [] }) => {
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [searchText, setSearchText] = useState("");

    // Récupère les IDs des recettes existantes
    const existingRecipeIds = existingMenuRecipes.map(recipe => recipe.id);

    const getRecipeCategory = (partId) => {
        switch (partId) {
            case 1: return "ENTRÉES";
            case 2: return "PLATS";
            case 3: return "DESSERTS";
            case 4: return "BOISSONS";
            default: return "AUTRES";
        }
    };

    const optionsWithCategories = recipes
        .filter(recipe => recipe && recipe.id && recipe.partId && recipe.name && recipe.description)
        .map(recipe => ({
            ...recipe,
            category: getRecipeCategory(recipe.partId),
        }));

    const filteredRecipes = optionsWithCategories.filter(recipe =>
        recipe.name.toLowerCase().includes(searchText.toLowerCase()) ||
        recipe.description.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleRecipeSelect = (recipeId) => {
        if (existingRecipeIds.includes(recipeId)) return; // Ignorer les recettes déjà existantes
        setSelectedRecipes(prevSelected =>
            prevSelected.includes(recipeId)
                ? prevSelected.filter(id => id !== recipeId)
                : [...prevSelected, recipeId]
        );
    };

    const handleAddClick = () => {
        const newRecipes = selectedRecipes.filter(id => !existingRecipeIds.includes(id)); // Exclure les recettes existantes
        onRecipeSelect(newRecipes);
        onAddRecipesToMenu(newRecipes);
        onClose();
    };

    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="popup-menu">
                        <div className="popup-menus-header">
                            <div className="popup-menus-title">
                                <h2>Choisir des recettes</h2>
                            </div>
                            <div className="popup-menus-content">
                                <input
                                    type="text"
                                    placeholder="Rechercher une recette..."
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginBottom: "10px",
                                        fontSize: "16px",
                                        boxSizing: "border-box",
                                    }}
                                />
                                <div className="popup-menu-actions">
                                    <button className="btn-full btn-sm" onClick={handleAddClick}>
                                        Ajouter les recettes
                                    </button>
                                    <button className="btn-border" onClick={onClose}>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="recipe-catalog">
                            {filteredRecipes.map((recipe) => (
                                <RecipeCard
                                    key={recipe.id}
                                    recipe={recipe}
                                    isSelected={selectedRecipes.includes(recipe.id)}
                                    isExisting={existingRecipeIds.includes(recipe.id)}
                                    isListContext={true} // Active le mode "liste" dans RecipeCard
                                    onSelect={handleRecipeSelect} // Gère la sélection via un callback
                                />
                            ))}
                        </div>
                    </div>

                </>
            )}
        </>
    );
};

export default PopupMenu;
