import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

//API
import { getRecipesByMenuId } from "../../../api/menuRecipesAPI"
import { fetchCombinedProductInfoById } from "../../../api/stockAPI"
import { getMenuById } from '../../../api/menuAPI'
import { createShoppingList, updateShoppingList, findProductsByMenuId } from '../../../api/shoppingListAPI'

//MUI
import Button from '@mui/joy/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from '@mui/material';


//Components
import ShoppingListTables from './ShoppingListTables';
import ShoppingListInsights from './ShoppingListInsights';
import MobileShoppingList from './MobileShoppingList';

//toast
import toast from 'react-hot-toast';

//Style
import "../../../views/admin-dashboard/menu-maker/Menu.css"



function ShoppingList() {

    const { id } = useParams();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [recipes, setRecipes] = useState([]);
    const [products, setProducts] = useState([]);
    const [menuInfos, setMenuInfos] = useState([])
    const [productsInfos, setProductsInfos] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const loadProductsInfo = async () => {
            try {
                const products = await findProductsByMenuId(id);
                setListProducts(products);
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.error("Erreur lors du chargement des produits par ID de menu :", error);
                toast.error("Erreur lors du chargement des informations des produits.");
            }
        };

        if (id) {
            loadProductsInfo();
        }
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedMenu = await getMenuById(id);
                setMenuInfos(fetchedMenu);

                const fetchedRecipes = await getRecipesByMenuId(id);
                setRecipes(fetchedRecipes);


                const combinedInfoPromises = fetchedRecipes.map(recipe => fetchCombinedProductInfoById(recipe.recipe_id));
                const combinedInfoResults = await Promise.all(combinedInfoPromises);

                setProducts(combinedInfoResults);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Erreur lors de la récupération des données du menu :", error);
                toast.error("Erreur lors de la récupération des données du menu");
            }
        };

        fetchData();
    }, [id]);



    useEffect(() => {
        const aggregateProducts = () => {
            const productMap = {};

            // Parcourir chaque menu (chaque élément de products)
            products.forEach(menu => {
                menu.recipeProductInfo.forEach(info => {
                    const product = menu.productInfos.find(p => p.id === info.productId);
                    if (product) {
                        // Vérifier si le produit a déjà été ajouté au productMap
                        if (!productMap[product.id]) {
                            // Find the corresponding product in listProducts by product_id
                            const listProduct = listProducts.find(lp => lp.product_id === product.id);
                            productMap[product.id] = {
                                id: product.id,
                                name: product.name,
                                unitQty: 0,
                                unitType: product.unitType,
                                price: parseFloat(product.price),
                                category: product.category,
                                // Use the tobuy value from listProducts, default to true if not found
                                tobuy: listProduct ? listProduct.tobuy : true,
                                recipesId: info.recipesId
                            };
                        }
                        // Ajouter la quantité de l'info de recette courante à la quantité totale du produit
                        productMap[product.id].unitQty += info.unitQty;
                    }
                });
            });

            return Object.values(productMap);
        };

        const aggregatedProducts = aggregateProducts();
        setProductsInfos(aggregatedProducts);



        const formData = {
            menu_id: id,
            products: aggregatedProducts.map(product => ({
                product_id: product.id,
                required_quantity: product.unitQty
            }))
        };



        const createList = async () => {
            try {
                const shoppingList = await createShoppingList(formData);

            } catch (error) {

                console.error('Error creating shopping list:', error);
            }
        };

        createList();
    }, [products, listProducts]);


    const updateShoppingListProduct = async (productId) => {
        try {
            const updatedProducts = productsInfos.map(product => ({
                ...product,
                tobuy: product.id === productId ? !product.tobuy : product.tobuy,
            }));
    
            const updatedProductData = {
                menu_id: menuInfos.id,
                products: updatedProducts.map(({ id, tobuy }) => ({ product_id: id, tobuy })),
            };
    
            await updateShoppingList(updatedProductData, id);
    
            // Utilisez un nouveau tableau pour forcer React à détecter le changement
            setProductsInfos([...updatedProducts]);
    
            toast.success("La liste de courses a été mise à jour avec succès.");
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la liste de courses :", error);
            toast.error("Erreur lors de la mise à jour de la liste de courses.");
        }
    };
    

    if (loading) {
        return <div>Loading...</div>;
    }


    return (

        <div className='shopping-list'>
            <div className="shopping-list-back-link">
                <Link
                    to={`/account/menumaker/${id}`}
                    rel="noopener noreferrer"
                >
                    <Button color="success" variant="plain" startDecorator={<ArrowBackIosIcon sx={{ width: 15 }} />}>
                        Retour au menu
                    </Button>
                </Link>
            </div>
            <div className="shopping-list-header">
                <h1 className="shopping-list-title">
                    Liste de Courses
                </h1>
            </div>
            <div className="shopping-list-content">
                {/* <div className="shopping-list-insights">
                 <ShoppingListInsights menuInfos={menuInfos} productsInfos={productsInfos} />
                 </div> */}
                <div className="shopping-list-tables">
                    {isMobile ? (
                        <MobileShoppingList products={products} productsInfos={productsInfos} menuInfos={menuInfos} updateShoppingListProduct={updateShoppingListProduct} />
                    ) : (
                        <ShoppingListTables products={products} menuInfos={menuInfos} productsInfos={productsInfos} updateShoppingListProduct={updateShoppingListProduct} />
                    )}
                </div>
                
            </div>
        </div>


    );
}



export default ShoppingList;