import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUnavailableItems, setCartFromLocalStorage, detectMenus, calculateTotalWithMenus} from '../../features/cart/cartSlice'; 
import { fetchCurrentUserOrders } from '../../api/userAPI';
import { createOrder, updateOrderById } from '../../api/ordersAPI'; 
import toast from 'react-hot-toast'; 

import CircularProgress from '@mui/material/CircularProgress';
import Button from '../elements/button/Button';
import CartItem from './CartItem';
import './SideCart.css';

function SideCart({ closeCartDropdown, isMobile }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // Récupérer les données du panier via Redux
    const cart = useSelector((state) => state.cart.items); 
    const isInDeliveryZone = useSelector((state) => JSON.parse(sessionStorage.getItem('isInDeliveryZone') || 'false'));
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    // Synchroniser le panier avec le localStorage au premier chargement
    useEffect(() => {
        dispatch(setCartFromLocalStorage()); 
    }, [dispatch]);

    // Gestion de l'expiration du panier
    useEffect(() => {
        try {
            const cartExpiration = localStorage.getItem('cartExpiration');
            if (cartExpiration && !isNaN(cartExpiration) && new Date().getTime() > parseInt(cartExpiration)) {
                dispatch(clearUnavailableItems()); 
                localStorage.removeItem('cart');
                localStorage.removeItem('cartExpiration');
                toast.info("Votre panier a expiré.");
            }
        } catch (error) {
            console.error("Error handling cart expiration:", error);
        }
    }, [dispatch]);

    const calculateMenuDiscount = (cart) => {
        const menus = detectMenus(cart);

        // Calcul des remises sur les menus
        const simpleMenuDiscount = menus.simpleMenuCount * 0.1; // 10% par menu simple
        const fullMenuDiscount = menus.fullMenuCount * 0.2; // 20% par menu complet

        return {
            simpleMenuCount: menus.simpleMenuCount,
            fullMenuCount: menus.fullMenuCount,
            totalDiscount: simpleMenuDiscount + fullMenuDiscount,
        };
    };

    const calculateTotalWithDiscount = (cart) => {
        const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const { totalDiscount } = calculateMenuDiscount(cart);
        const discountAmount = subtotal * totalDiscount;
        return {
            subtotal: subtotal.toFixed(2),
            discountAmount: discountAmount.toFixed(2),
            total: (subtotal - discountAmount).toFixed(2),
        };
    };

    // Calcul du total en euros pour affichage
    const { total, menus, totalBeforeDiscount, discountAmount } = useMemo(() => calculateTotalWithMenus(cart), [cart]);
    const menusDetected = useMemo(() => detectMenus(cart), [cart]);

    // Conversion du total en centimes pour la base de données
    const totalInCents = Math.round(total * 100);

    const handleCheckout = async () => {
        setIsLoading(true);
        try {
            if (cart.length === 0) {
                toast.error('Votre panier est vide.');
                setIsLoading(false);
                return;
            }

            const user = JSON.parse(localStorage.getItem('user'));
            if (!user || !user.accessToken) {
                toast.error('Veuillez vous connecter pour valider votre commande.');
                navigate('/login');
                setIsLoading(false);
                return;
            }

            const userId = user.id;
            const orders = await fetchCurrentUserOrders(userId);

            let currentOrder = orders.find(
                (order) =>
                    order.status === 'new' ||
                    (order.status === 'accepted' && order.payment_status === false)
            );

            if (currentOrder) {
                if (currentOrder.status === 'accepted' && currentOrder.payment_status === false) {
                    currentOrder.status = 'new';
                }

                const currentOrderItems = currentOrder.order_items.map((item) => ({
                    id: item.id,
                    quantity: item.quantity,
                }));

                const cartItems = cart.map((item) => ({
                    id: item.id,
                    quantity: item.quantity,
                }));

                const isSameCart = JSON.stringify(currentOrderItems) === JSON.stringify(cartItems);

                if (!isSameCart || currentOrder.price !== totalInCents) {
                    currentOrder = {
                        ...currentOrder,
                        order_items: [...cart],
                        price: totalInCents,
                    };
                    await updateOrderById(currentOrder.id, currentOrder);
                }
            } else {
                currentOrder = await createOrder({
                    userId: userId,
                    status: 'new',
                    payment_status: false,
                    order_items: [...cart],
                    price: totalInCents,
                });
            }

            localStorage.setItem('currentOrder', JSON.stringify(currentOrder));

            toast.success("Commande prête pour validation !");
            closeCartDropdown();
            navigate('/order-validation');
        } catch (error) {
            console.error("Erreur lors du traitement de la commande:", error);
            toast.error("Une erreur est survenue lors du traitement de la commande.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`side-cart-container ${isMobile ? "dropdown-mobile-cart" : ""}`}>
            <div className="side-cart-items-list">
                <h2 className="side-cart-title flex-center">Ma commande</h2>
                <div className="side-cart-items">
                    {cart.length === 0 ? (
                        <p className="flex-center">Votre panier est vide.</p>
                    ) : (
                        cart.map((item) => (
                            <CartItem
                                key={item.id}
                                item={item}
                                isInDeliveryZone={isInDeliveryZone}
                                preorderEnabled={preorderEnabled}
                            />
                        ))
                    )}
                </div>
            </div>
            {cart.length > 0 && (
                <>
                    <div className="side-cart-recap">
                        <h2 className="side-cart-total-title">Récapitulatif</h2>
                        <div className="side-cart-menu">
                        {menus.simpleMenuCount > 0 && (
                            <div className="side-cart-menus">
                                <span>Menus duo :</span>
                                <span>{menus.simpleMenuCount} x 9,90 €</span>
                            </div>
                        )}
                        {menus.fullMenuCount > 0 && (
                            <div className="side-cart-menus">
                                <span>Menus trio :</span>
                                <span>{menus.fullMenuCount} x 11,90 €</span>
                            </div>
                        )}
                        </div>
                        <div className="side-cart-summary">
                            <div className="summary-item">
                                <span>Total avant réduction :</span>
                                <span>{totalBeforeDiscount} €</span>
                            </div>
                            <div className="summary-item">
                                <span>Économies réalisées :</span>
                                <span>-{discountAmount} €</span>
                            </div>
                            <div className="summary-item">
                                <span>Total après réduction :</span>
                                <span>{total} €</span>
                            </div>
                        </div>
                    </div>
                    <div className="side-cart-action">
                        <Button
                            className="btn-border btn-xl"
                            onClick={handleCheckout}
                            disabled={isLoading}
                            text={
                                isLoading ? (
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress size={24} color="inherit" style={{ marginRight: 8 }} />
                                        Traitement en cours...
                                    </span>
                                ) : ("Valider la commande")
                            }
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default SideCart;
