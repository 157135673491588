import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import toast from "react-hot-toast";
import { updateRecipeImage, uploadRecipeImage } from "../../../api/recipesAPI";

function RecipeImage({ recipe }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserRole, setCurrentUserRole] = useState(null);

    const isAdmin = currentUserRole === "admin";
    const isCreator = recipe && currentUser === recipe.user_id;
    const canEdit = isAdmin || isCreator;

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.id) setCurrentUser(user.id);
        if (user?.roleId) setCurrentUserRole(user.roleId);
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setSelectedImageName(file ? file.name : "");
    };

    const handleImageUpdate = async () => {
        if (!selectedImage) {
            toast.error("Veuillez sélectionner une image.");
            return;
        }
        try {
            if (recipe.imageUrl) {
                // Si une image existe déjà, la mettre à jour
                await updateRecipeImage(recipe.id, selectedImage);
                toast.success("Image mise à jour avec succès !");
            } else {
                // Si aucune image n'existe, uploader une nouvelle image
                const formData = new FormData();
                formData.append("recipeId", recipe.id);
                formData.append("image", selectedImage);
    
                await uploadRecipeImage(recipe.id, selectedImage);
                toast.success("Image ajoutée avec succès !");
            }
            setSelectedImage(null);
            setSelectedImageName("");
        } catch (error) {
            console.error("Erreur lors de la mise à jour ou de l'ajout de l'image :", error);
            toast.error("Impossible de mettre à jour ou d'ajouter l'image.");
        }
    };

    return (
        <div>
        {/* Vérification si l'utilisateur peut éditer */}
        {canEdit && (
            <div className="recipes-details-img-actions">
                <label htmlFor="imageInput" className="btn-add">
                    <GoPlus style={{ width: 15, color: "green" }} />{" "}
                    {recipe.imageUrl ? "Modifier la photo" : "Ajouter une photo"}
                </label>
                <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                />
                {selectedImageName && (
                    <div>
                        <span style={{ fontWeight: "bold" }}>Image sélectionnée :</span> {selectedImageName}
                        <button
                            style={{ marginLeft: "1rem" }}
                            className="btn-update btn-add"
                            onClick={handleImageUpdate}
                        >
                            Valider
                        </button>
                    </div>
                )}
            </div>
        )}
        <div className="flex-center">
            <div className="recipes-details-img-container">
                {recipe.imageUrl ? (
                    <img
                        src={recipe.imageUrl}
                        alt={recipe.name || "Recipe Image"}
                        className="recipes-details-img"
                    />
                ) : (
                    <p>Aucune image disponible</p>
                )}
            </div>
        </div>
    </div>
);
}

export default RecipeImage;
