import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// API
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { getOrderDetailsByOrderNum } from "../../api/ordersAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';
import { createInstantPayment } from '../../api/paygreenAPI';

// Elements
import PopupFridge from '../elements/popup/popup-fridge/PopupFridge';

// Toast for notifications
import toast from 'react-hot-toast';

// Icons
import { FaRegUser, FaRegStar } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFood } from "react-icons/pi";

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientNavbar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // États locaux
    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [showPopupFridge, setShowPopupFridge] = useState(false);

    // Nouveaux états
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);

    // Récupération des données du store Redux
    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userInfoData = await fetchCurrentUserInfos();
                setUserInfo(userInfoData);
    
                const ordersData = await fetchCurrentUserOrders();
    
                if (ordersData && ordersData.length > 0) {
                    const unpaidOrder = ordersData.find(order => 
                        (order.status === "accepted" || order.status === "completed") && 
                        !order.payment_status
                    );
                    setUnpaidOrdersExist(!!unpaidOrder);
                    setUnpaidOrderNum(unpaidOrder?.order_num || null);
    
                    const readyOrder = ordersData.find(order => 
                        order.status === "accepted" && 
                        order.payment_status && 
                        order.type === "pickup" // Vérifie si le type de commande est "pickup"
                    );
                    setReadyOrderExist(!!readyOrder);
                    setReadyOrderNum(readyOrder?.order_num || null);
                }
    
                // Vérification des commandes groupées
                const participant = await fetchParticipantByUserId(userInfoData.id);
                const groupOrderActive = participant && ordersData.some(order => order.id === participant.order_id);
                setGroupOrderExist(!!groupOrderActive);
    
                const subscriptionResponse = await fetchSubscription(userInfoData.id);
                if (subscriptionResponse?.data) {
                    const isActive = subscriptionResponse.data.active;
                    dispatch(setSubscriptionExists({
                        exists: isActive,
                        subscriptionInfos: { ...subscriptionResponse.data, active: isActive },
                    }));
                } else {
                    dispatch(resetSubscription());
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données utilisateur :", error);
                toast.error("Erreur lors du chargement des données. Veuillez réessayer.");
            }
        };
    
        fetchData();
    }, [dispatch]);

    const handleRestrictedAccess = (path) => {
        if (!subscriptionExists || !subscriptionInfos?.active) {
            toast.error("Vous devez être abonné pour accéder à cette section.");
            navigate('/account/subscriptions');
        } else {
            navigate(path);
        }
    };

    const initiatePaygreenPayment = async (orderNum, amountInCents) => {
        try {
            if (!amountInCents || !orderNum) {
                throw new Error("Données de paiement manquantes.");
            }

            const paymentResult = await createInstantPayment(amountInCents, `${orderNum}`, orderNum);

            if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
                toast.success("Redirection vers la passerelle de paiement...");
                window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
            } else {
                throw new Error("URL de paiement non reçue.");
            }
        } catch (error) {
            console.error("Erreur lors du paiement Paygreen :", error);
            toast.error("Une erreur est survenue lors du paiement. Veuillez réessayer.");
        }
    };

    const handleButtonClick = async () => {
        try {
            if (unpaidOrdersExist && unpaidOrderNum) {
                // Cas où une commande impayée existe
                const order = await getOrderDetailsByOrderNum(unpaidOrderNum);
                const amount = parseFloat(order.price);
                if (!amount || isNaN(amount)) {
                    throw new Error("Montant manquant ou invalide pour le paiement.");
                }
                const amountInCents = Math.round(amount);
                await initiatePaygreenPayment(unpaidOrderNum, amountInCents);
            } else if (readyOrderExist && readyOrderNum) {
                // Cas où une commande prête existe
                setShowPopupFridge(true);
            } else {
                // Cas où aucune commande n'est disponible
                toast.error("Aucune commande disponible pour le paiement ou la récupération.");
            }
        } catch (error) {
            console.error("Erreur lors de la gestion du bouton :", error);
            toast.error("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    return (
        <nav className="client-navbar">
            <div className="admin-title">
                <h1>Bonjour {userInfo.firstname || "Stranger"}</h1>
            </div>
            <button
                className="btn-navbar"
                onClick={() => navigate("/")} // Redirige vers la route racine
            >Commander un repas
            </button>
            <NavLink to="/account/profil" className="admin-navbar-links">
                <FaRegUser className="client-navbar-icons" size={15} /> Profil
            </NavLink>
            <NavLink to="/account/my-orders" className="admin-navbar-links">
                <TbTruckDelivery className="client-navbar-icons" size={15} /> Commandes
            </NavLink>
            {groupOrderExist && (
                <NavLink to="/account/orders-tracking" className="admin-navbar-links">
                    <TbTruckDelivery className="client-navbar-icons" size={15} /> Suivi des commandes groupées
                </NavLink>
            )}
            <NavLink to="/account/subscriptions" className="admin-navbar-links">
                <FaRegStar className="client-navbar-icons" size={15} /> Abonnements
            </NavLink>
            {subscriptionExists && subscriptionInfos?.subscription_type === "pro_chef" && (
                <NavLink to="/account/stocks" className="admin-navbar-links">
                    <IoRestaurantOutline className="client-navbar-icons" size={15} /> Produits
                </NavLink>
            )}
            <button className="admin-navbar-links" onClick={() => handleRestrictedAccess('/account/recipes')}>
                <IoRestaurantOutline className="client-navbar-icons" size={15} /> Recettes
            </button>
            <button className="admin-navbar-links" onClick={() => handleRestrictedAccess('/account/menulist')}>
                <PiBowlFood className="client-navbar-icons" size={15} /> Menu Maker
            </button>
            <div className="client-action">
                {(unpaidOrdersExist || readyOrderExist) && (
                    <button className="btn-navbar" onClick={handleButtonClick}>
                        {unpaidOrdersExist ? "Payer la commande" : "Ouvrir le frigo"}
                    </button>
                )}
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </nav>
    );
}

export default ClientNavbar;
