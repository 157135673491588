import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function ShoppingListInsights({ menuInfos, productsInfos }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [totalProducts, setTotalProducts] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        // Recalculer les produits valides
        const validProducts = productsInfos.filter(product => product.price && product.unitQty);

        // Mettre à jour le nombre total de produits
        setTotalProducts(validProducts.length);

        // Recalculer le coût total
        const newTotalCost = validProducts.reduce((total, product) => {
            const adjustedUnitQty = product.unitQty / (product.portions || 1);
            const pricePerGram = product.price / 1000;
            const totalPrice = adjustedUnitQty * pricePerGram;
            return total + (isNaN(totalPrice) ? 0 : totalPrice);
        }, 0).toFixed(2);

        setTotalCost(newTotalCost);
    }, [productsInfos]); // Recalcul à chaque changement de productsInfos

    return (
        <div className='insights'>
            <div className="c">
                <Card sx={{ minWidth: 170, borderRadius: 8 }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography
                            variant={isMobile ? "h4" : "h2"}
                            component="div"
                            sx={{ fontWeight: 900, margin: "1rem 0", fontFamily: "'Montserrat', sans-serif" }}
                        >
                            {totalCost + "€"}
                        </Typography>
                        {isMobile ? (
                            <Typography variant="body2" color="text.secondary">
                                Coût total
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                Coût total pour {menuInfos.portions} portions
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </div>
            <div className="insights-products">
                <Card sx={{ minWidth: 170, borderRadius: 8 }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography
                            variant={isMobile ? "h4" : "h2"}
                            component="div"
                            sx={{ fontWeight: 900, margin: "1rem 0", fontFamily: "'Montserrat', sans-serif" }}
                        >
                            {totalProducts}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Nombre de produits
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default ShoppingListInsights;
