import apiInstance from "./api";
import { getApiHeaders } from "./apiHeaders";
/**
 * Fetches the list of products from the API.
 *
 * @returns {Promise<Array>} The list of products.
 * @throws {Error} If there is an error fetching the products.
 */
export const fetchProduct = async () => {
  
    try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/product/list-all`, 
        { headers: getApiHeaders() }
      );
      return response.data;
      
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du stock de produits",
        error
      );
      throw error;
    }
};

  /**
   * Creates a product using the provided data.
   *
   * @param {Object} formData - The data of the product to create.
   * @returns {Promise<Object>} The data of the created product.
   * @throws {Error} If there is an error creating the product.
   */
  export const createProduct = async (formData) => {
  
    try {
      const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/product/create`, formData, { headers: getApiHeaders() });
  

      return response.data;
    } catch (error) {
      console.error("Error creating the product:", error);
      throw error;
    }
  };

  export const fetchProductById = async (id) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": "fr",
    };
  
    try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/product/list/${id}`, { headers: headers });
      return response.data;
      
    } catch (error) {
      console.error(
        `Erreur lors de la récupération des infos du produits avec l'id ${id}`,
        error
      );
      throw error;
    }
  };

  // stockAPI.js

/**
 * Récupère les informations des produits liés et tous les produits pour une recette donnée.
 *
 * @param {string} recipeId - L'ID de la recette.
 * @returns {Promise<Object>} Les informations des produits liés et la liste complète des produits.
 * @throws {Error} En cas d'erreur lors de la récupération des données.
 */
export const fetchDataForRecipe = async (recipeId) => {
  try {
    // Récupère les informations combinées des produits pour la recette
    const combinedProductInfo = await fetchCombinedProductInfoById(recipeId);
    const allProducts = await fetchProduct();

    // Formater les produits liés pour inclure "productLabel" pour le filtrage
    const linkedProducts = combinedProductInfo.productInfos.map((product) => ({
      productLabel: product.name, // Utiliser le nom comme label pour le filtrage
      productId: product.id,
      unitType: product.unitType,
      origin: product.label,
    }));

    return {
      linkedProducts,
      allProducts: allProducts.map((product) => ({
        label: product.name,
        value: product.id,
        unitType: product.unitType,
        origin: product.label
      })),
    };
  } catch (error) {
    console.error('Error fetching data for recipe:', error);
    throw error;
  }
};


/**
 * Updates a product using the provided ID and data.
 *
 * @param {string} id - The ID of the product to update.
 * @param {Object} updatedProductData - The updated product data.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error updating the product.
 */
export const updateProductById = async (id, updatedProductData) => {
  console.log("Appel API avec les données :", updatedProductData);
  try {
      const response = await apiInstance.put(
          `${process.env.REACT_APP_API_URL}/product/update/${id}`,
          updatedProductData,
          { headers: getApiHeaders() }
      );
      return response.data;
  } catch (error) {
      console.error("Erreur dans updateProductById :", error.response || error);
      throw error;
  }
};

/**
 * Deletes a product using the provided ID.
 *
 * @param {string} id - The ID of the product to delete.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error deleting the product.
 */
export const deleteProductById = async (id) => {

  try {
    const response = await apiInstance.delete(`${process.env.REACT_APP_API_URL}/product/delete/${id}`,  
    { headers: getApiHeaders() });

    return response.data; 
  } catch (error) {
    console.error("Error deleting the product:", error);
    throw error;
  }
};


  /**
   * Links products to a recipe using the provided data.
   *
   * @param {string} recipeId - The ID of the recipe.
   * @param {string[]} productIds - The IDs of the products to link.
   * @param {number[]} unitQtys - The quantities of each product to link.
   * @returns {Promise<Object>} The response data.
   * @throws {Error} If there is an error linking the products to the recipe.
   */
  export const linkProductsToRecipe = async (recipeId, productIds, unitQtys) => {

    const linkProductsData = {
        recipeId,
        productIds,
        unitQtys,
    };

    try {
        const response = await apiInstance.post(
            `${process.env.REACT_APP_API_URL}/product/link-products`,
            linkProductsData,
            { headers: getApiHeaders() }
        );

        return response.data;
    } catch (error) {
        console.error("Erreur lors de la liaison des produits à la recette:", error);
        throw error;
    }
};

/**
 * Fetches products by recipe ID.
 *
 * @param {string} recipeId - The ID of the recipe.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error fetching the products.
 */
export const fetchProductByRecipeId = async (recipeId) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
  };
  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipe-products/get-all/${recipeId}`, { headers: headers });

    return response.data;
    
  } catch (error) {
    console.error(
      `Erreur lors de la récupération des produits pour la recette ${recipeId}`,
      error
    );
    throw error;
  }
};


/**
 * Fetches recipe products by recipe ID.
 *
 * @param {string} recipeId - The ID of the recipe.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error fetching the products.
 */
export const fetchRecipeProductsById = async (recipeId) => {

    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipe-products/get-all/${recipeId}`, { headers: getApiHeaders() });

        return response.data;
    } catch (error) {
        console.error(`Erreur lors de la récupération des produits de la recette ${recipeId} :`, error);
        throw error;
    }
};

/**
 * Fetches recipe product information by product ID.
 *
 * @param {string} productId - The ID of the product.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error fetching the product information.
 */
export const fetchRecipeProductInfosById = async (productId) => {

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipe-products/list/${productId}`, { headers: getApiHeaders() });
    
    return response.data; 
    
  } catch (error) {
    console.error(
      `Erreur lors de la récupération des infos du produit avec l'id ${productId}`,
      error
    );
    throw error;
  }
};



/**
 * Fetches combined product information by recipe ID.
 *
 * @param {string} recipeId - The ID of the recipe.
 * @returns {Promise<Object>} The combined product information.
 * @throws {Error} If there is an error fetching the combined product information.
 */
export const fetchCombinedProductInfoById = async (recipeId) => {
  try {

    const productIdsResponse = await fetchProductByRecipeId(recipeId);


    if (productIdsResponse.success) {
      const productIds = productIdsResponse.productIds.map((product) => product.productId);
      const productInfoPromises = productIds.map((productId) => fetchProductById(productId));
      const productInfos = await Promise.all(productInfoPromises);

      return {
        recipeProductInfo: productIdsResponse.productIds,
        productInfos,
      };
    } else {
      throw new Error(`Erreur lors de la récupération des IDs de produits pour la recette ${recipeId}`);
    }
  } catch (error) {
    console.error(`Erreur lors de la récupération des informations combinées pour la recette ${recipeId}`, error);
    throw error;
  }
};

/**
 * Update the product information of the recipe by ID.
 *
 * @param {string} productId - The ID of the product.
 * @param {Object} updatedProductData - The updated product data.
 * @returns {Promise<Object>} The updated product data.
 * @throws {Error} If there is an error updating the product information.
 */
export const updateRecipeProductInfoById = async (productId, updatedProductData) => {

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/recipe-products/update/${productId}`,
      updatedProductData,
      { headers: getApiHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification des informations du produit de la recette :", error);
    throw error;
  }
};

/**
 * Deletes the product information of the recipe by ID.
 *
 * @param {string} productId - The ID of the product.
 * @param {string} recipeId - The ID of the recipe.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error deleting the product information.
 */
export const deleteRecipeProductInfoById = async (productId, recipeId) => {

  const deleteData = {
    recipeId,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/recipe-products/delete/${productId}`,
      {
        headers: getApiHeaders(),
        data: deleteData, 
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting the recipe product info:", error);
    throw error;
  }
};

export const verifyIfAllergen = async (productId) => {

  try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/product-allergy/get/${productId}`);

      return response.data;
  } catch (error) {
      console.error(`Erreur lors de la vérification du produit ${productId} :`, error);
      throw error;
  }
};