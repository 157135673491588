import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    Autocomplete,
    TextField,
    Pagination,
    CircularProgress,
    useMediaQuery,
    Stack,
    Button,
    Typography,
} from "@mui/material";
import PopupRecipe from "../../elements/popup/popup-recipe/PopupRecipe";
import ButtonComponent from "../../elements/button/Button";
import RecipeCard from "../../recipes/RecipeCard";
import {
    fetchAllRecipePictures,
    fetchRecipeByUserId,
    createRecipe,
    uploadRecipeImage,
} from "../../../api/recipesAPI";
import { fetchDataForRecipe } from "../../../api/stockAPI";
import toast from "react-hot-toast";
import "./mobile/MobileRecipe.css";

function RecipesTable() {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const isMobile = useMediaQuery("(max-width: 600px)");
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);
    const navigate = useNavigate();


    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userId = user?.id;

    const [recipes, setRecipes] = useState([]);
    const [userRecipesCount, setUserRecipesCount] = useState(0);
    const [recipesLoading, setRecipesLoading] = useState(true);
    const [filterLoading, setFilterLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [showUserRecipesOnly, setShowUserRecipesOnly] = useState(false);


    const isAdmin = JSON.parse(localStorage.getItem("roles") || "[]").includes("admin");

    const fetchRecipesWithDetails = async () => {
        try {
            setRecipesLoading(true);
            console.log("Fetching recipes for user ID:", userId);

            const [recipesData, picturesData] = await Promise.all([
                fetchRecipeByUserId(),
                fetchAllRecipePictures(),
            ]);

            console.log("Raw recipes data:", recipesData);

            const recipesWithDetails = recipesData.map((recipe) => {
                const picture = picturesData.find((pic) => pic.recipe_id === recipe.id);
                return {
                    ...recipe,
                    imageUrl: picture ? `${process.env.REACT_APP_API_URL}${picture.path}` : null,
                    linkedProducts: [],
                };
            });

            setRecipes(recipesWithDetails);
            setUserRecipesCount(recipesWithDetails.filter((recipe) => recipe.user_id === userId).length);

            fetchIngredientsForFilter(recipesWithDetails);
        } catch (error) {
            console.error("Error fetching recipes:", error);
            setHasError(true);
        } finally {
            setRecipesLoading(false);
        }
    };

    const fetchIngredientsForFilter = async (recipes) => {
        try {
            setFilterLoading(true);

            const detailedRecipes = await Promise.all(
                recipes.map(async (recipe) => {
                    try {
                        const recipeDetails = await fetchDataForRecipe(recipe.id);
                        return {
                            ...recipe,
                            linkedProducts: recipeDetails.linkedProducts || [],
                            allProducts: recipeDetails.allProducts || [],
                        };
                    } catch {
                        console.warn(`Error fetching details for recipe ID: ${recipe.id}`);
                        return recipe;
                    }
                })
            );

            setRecipes(detailedRecipes);

            const allIngredients = detailedRecipes.flatMap((recipe) => recipe.allProducts || []);
            const uniqueIngredients = [...new Set(allIngredients.map((item) => item.label))];
            setIngredientsList(uniqueIngredients);
        } catch (error) {
            console.error("Error fetching ingredients:", error);
        } finally {
            setFilterLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipesWithDetails();
    }, []);

    useEffect(() => {
        setCurrentPage(1); // Reset pagination when filters change
    }, [searchTerm, selectedIngredients]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const filteredRecipes = recipes.filter((recipe) => {
        const searchTermLower = searchTerm.toLowerCase();
        const matchesSearchTerm =
            !searchTerm ||
            recipe.name.toLowerCase().includes(searchTermLower) ||
            recipe.description.toLowerCase().includes(searchTermLower);
    
        const containsExcludedIngredients = selectedIngredients.some((excluded) =>
            recipe.linkedProducts?.some((product) => product.productLabel === excluded)
        );
    
        const isUserRecipe = showUserRecipesOnly ? recipe.user_id === userId : true;
    
        return matchesSearchTerm && !containsExcludedIngredients && isUserRecipe;
    });

    const paginatedRecipes = filteredRecipes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleCreateRecipe = async (recipeData) => {
      try {
          // 1. Envoyer les données de la recette
          const response = await createRecipe(recipeData);
          const newRecipe = response.data;
  
          // 2. Si une image est présente, la téléverser
          if (recipeData.image) {
              const formData = new FormData();
              formData.append("recipeId", newRecipe.id);
              formData.append("image", recipeData.image);
  
              try {
                  await uploadRecipeImage(formData);
              } catch (imageError) {
                  console.error("Erreur lors de l'upload de l'image :", imageError);
                  toast.error("La recette a été créée, mais l'image n'a pas été téléversée.");
              }
          }
  
          // 3. Mettre à jour les recettes locales
          setRecipes((prevRecipes) => [...prevRecipes, newRecipe]);
          toast.success("Recette créée avec succès !");
      } catch (error) {
          console.error("Erreur lors de la création de la recette :", error);
          toast.error("Erreur lors de la création de la recette.");
      }
  };
  

    return (
        <section className={isMobile ? "recipe-list-mobile" : "recipe-list-desktop"}>
          <div style={{ textAlign: "right", margin: isMobile ? "1rem" : "5rem 1rem 2rem" }}>
            {(isAdmin || 
              (subscriptionExists && subscriptionInfos.subscription_type !== "free") || 
              (subscriptionExists && subscriptionInfos.subscription_type === "free" && userRecipesCount === 0)) ? (
                <PopupRecipe
                trigger={
                    <ButtonComponent
                        className="btn-add"
                        type="button"
                        text={isMobile ? "+ ajouter une recette" : "+ Ajouter une recette"}
                        onClick={() => setIsCreatePopupOpen(true)}
                    />
                }
                onCreate={handleCreateRecipe}
            />
            ) : (
              <Button
                className="btn-upgrade"
                color="error"
                size="small"
                onClick={() => navigate('/account/subscriptions')}
              >
                Mettre à niveau
              </Button>
            )}
          </div>
          <Button
                variant="contained"
                color={showUserRecipesOnly ? "primary" : "inherit"}
                onClick={() => setShowUserRecipesOnly((prev) => !prev)}
                sx={{ marginBottom: "1rem" }}
            >
                {showUserRecipesOnly ? "Afficher toutes les recettes" : "Mes recettes uniquement"}
            </Button>
    
          <div className={`recipe-header ${isMobile ? "mobile-header" : "desktop-header"}`}>
            {/* Adaptation pour les barres de recherche */}
            <TextField
              className="search"
              type="text"
              placeholder={isMobile ? "Rechercher..." : "Rechercher une recette..."}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: "100%" }}
            />
            <Autocomplete
              multiple
              options={ingredientsList}
              value={selectedIngredients}
              onChange={(event, newValue) => setSelectedIngredients(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Exclure les ingrédients..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {filterLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              noOptionsText="Aucun ingrédient disponible"
            />
          </div>
    
          <div className="recipe-list">
            {recipesLoading ? (
              <CircularProgress />
            ) : hasError ? (
              <Typography variant="body2" color="error">
                Une erreur est survenue.
              </Typography>
            ) : paginatedRecipes.length > 0 ? (
              paginatedRecipes.map((recipe) => (
                <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                  <RecipeCard recipe={recipe} isListContext={true} />
                </Link>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                Aucun résultat trouvé.
              </Typography>
            )}
          </div>
    
          {/* Pagination */}
          <div className="pagination">
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: "20px", marginBottom: "1rem" }}
            >
              <Pagination
                count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                size={isMobile ? "small" : "medium"}
                variant="outlined"
              />
            </Stack>
          </div>
        </section>
      );
    }

export default RecipesTable;
