import { useState, useEffect } from 'react';
import RecipeCard from './RecipeCard';
import { useParts, fetchAllRecipePictures, synchFridgeQuantity } from '../../api/recipesAPI';
import CircularProgress from '@mui/material/CircularProgress';  // Loader de Material UI (ou un autre selon la lib que tu utilises)
import Box from '@mui/material/Box';
import './Recipes.css';


function RecipesList() {
    const [pictures, setPictures] = useState([]);
    const [loading, setLoading] = useState(true); // État de chargement

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true); // Commence le chargement
                const pics = await fetchAllRecipePictures();
                setPictures(pics);
                await synchFridgeQuantity();
            } catch (err) {
                console.error("Failed to synchronize quantities or fetch pictures", err);
            } finally {
                setLoading(false); // Le chargement est terminé
            }
        };

        loadData(); // Appel pour charger les données au montage du composant

    }, []);

    const { parts, loading: partsLoading, error } = useParts();

    // Combiner les états de chargement
    const isLoading = loading || partsLoading;

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress sx={{ color: "darkgreen" }} />
            </Box>
        );
    }

    if (error) return <div>Error loading parts and recipes. Please try again.</div>;

    return (
        <div className='parts-list'>
            {parts.map(part => (
                <section key={part.id} id={part.name}>
                    <div className="parts-list-title-score">
                        <h2 className='part-title'>{part.name}</h2>
                    </div>

                    <div className='recipes-list'>
                        {part.recipes.map(recipe => {
                            const recipePictures = pictures.filter(pic => pic.recipe_id === recipe.id);
                            return (
                                <RecipeCard
                                    key={recipe.id}
                                    recipe={{ ...recipe, pictures: recipePictures }}
                                />
                            );
                        })}
                    </div>
                </section>
            ))}
        </div>
    );
}

export default RecipesList;
