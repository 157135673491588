import React, { useState, useEffect } from "react";
import "../popup-edit/popupEdit.css";
import { fetchAllAllergies, updateAllergiesByUserId } from "../../../../api/allergiesAPI";

export default function PopupEditProduct({ open, onClose, data, onSave }) {
    const [editedData, setEditedData] = useState({});
    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [selectedAllergen, setSelectedAllergen] = useState(""); // Single allergen selection

    const categoriesOptions = [
        { value: "Fruits et Légumes", label: "Fruits et Légumes" },
        { value: "Conserves", label: "Conserves" },
        { value: "Epices et Condiments", label: "Epices et Condiments" },
        { value: "Céréales et Féculents", label: "Céréales et Féculents" },
        { value: "Boissons", label: "Boissons" },
        { value: "Huiles et matières grasses", label: "Huiles et matières grasses" },
        { value: "Légumineuses", label: "Légumineuses" },
        { value: "Sucreries snacks", label: "Sucreries snacks" },
        { value: "Oléagineux et Fruits secs", label: "Oléagineux et Fruits secs" },
        { value: "Aliments transformés", label: "Aliments transformés" },
    ];

    const labelsOptions = [
        { value: "", label: "Aucun" },
        { value: "bio", label: "Bio" },
        { value: "local", label: "Local" },
        { value: "national", label: "National" },
    ];

    // Charger les données initiales du produit
    useEffect(() => {
        if (data) {
            setEditedData(data);
            setSelectedAllergen(data.allergen || ""); // Initialiser avec l'allergène existant
        }
    }, [data]);

    // Charger tous les allergènes disponibles
    useEffect(() => {
        const fetchAllergiesData = async () => {
            try {
                const allergies = await fetchAllAllergies();
                const formattedOptions = allergies.map((allergy) => ({
                    value: allergy.id,
                    label: allergy.name,
                }));
                setAllergiesOptions(formattedOptions);
            } catch (error) {
                console.error("Erreur lors de la récupération des allergènes :", error);
            }
        };

        fetchAllergiesData();
    }, []);

    const handleFieldChange = (field, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleAllergenChange = (value) => {
        setSelectedAllergen(value); // Met à jour la sélection de l'allergène
    };

    const handleSave = async () => {
        try {
            // Mettre à jour l'allergène sélectionné
            if (selectedAllergen) {
                await updateAllergiesByUserId(data.id, [selectedAllergen]);
            } else {
                console.log("Aucun allergène sélectionné.");
            }

            // Sauvegarder les autres données du produit
            onSave({ ...editedData, allergen: selectedAllergen });
            onClose();
        } catch (error) {
            console.error("Erreur lors de la sauvegarde des allergènes liés :", error);
        }
    };

    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="edit-modal">
                        <div className="edit-modal-title">
                            <h2>Modifier le produit</h2>
                        </div>
                        <div className="edit-modal-form">
                            <div className="edit-fields">
                                <div className="edit-field">
                                    <label htmlFor="name">Nom du produit</label>
                                    <input
                                        id="name"
                                        type="text"
                                        value={editedData.name || ""}
                                        onChange={(e) => handleFieldChange("name", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="price">Prix</label>
                                    <input
                                        id="price"
                                        type="number"
                                        value={editedData.price || ""}
                                        onChange={(e) => handleFieldChange("price", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="totalQuantity">Quantité Totale</label>
                                    <input
                                        id="totalQuantity"
                                        type="number"
                                        value={editedData.totalQuantity || ""}
                                        onChange={(e) => handleFieldChange("totalQuantity", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="category">Catégorie</label>
                                    <select
                                        id="category"
                                        value={editedData.category || ""}
                                        onChange={(e) => handleFieldChange("category", e.target.value)}
                                        className="edit-input"
                                    >
                                        <option value="">Sélectionnez...</option>
                                        {categoriesOptions.map((category) => (
                                            <option key={category.value} value={category.value}>
                                                {category.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="label">Label</label>
                                    <select
                                        id="label"
                                        value={editedData.label || ""}
                                        onChange={(e) => handleFieldChange("label", e.target.value)}
                                        className="edit-input"
                                    >
                                        {labelsOptions.map((label) => (
                                            <option key={label.value} value={label.value}>
                                                {label.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="allergen">Allergène</label>
                                    <select
                                        id="allergen"
                                        value={selectedAllergen}
                                        onChange={(e) => handleAllergenChange(e.target.value)}
                                        className="edit-input"
                                    >
                                        <option value="">Aucun allergène</option>
                                        {allergiesOptions.map((allergy) => (
                                            <option key={allergy.value} value={allergy.value}>
                                                {allergy.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="edit-modal-action">
                                <button className="btn-full" onClick={handleSave}>
                                    Enregistrer
                                </button>
                                <button className="btn-border" onClick={onClose}>
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
