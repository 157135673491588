import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// MUI
import { styled, Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Paper, IconButton, Typography } from "@mui/material";
import Chip from '@mui/joy/Chip';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';

// Icons
import { TiDeleteOutline } from 'react-icons/ti';
import ShareIcon from '@mui/icons-material/Share';

// Components
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import PopupAddMenu from '../../elements/popup/popup-menus/PopupAddMenu';
import ButtonComponent from '../../elements/button/Button';

// API 
import { getMenuByUser, createMenu, deleteMenuById, countMenuByUserId } from '../../../api/menuAPI';

// toast
import toast from 'react-hot-toast';

// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default function MenusTable() {

    // Local states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [searchTerm, setSearchTerm] = useState("");
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [menusData, setMenusData] = useState([]);
    const [menuCount, setMenuCount] = useState(0);  // Compter les menus de l'utilisateur

    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMenuCount = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user.id;
                const countData = await countMenuByUserId(userId);
                setMenuCount(countData.menuCount);
            } catch (error) {
                console.error("Erreur lors de la récupération du nombre de menus :", error);
            }
        };

        if (subscriptionExists && subscriptionInfos.subscription_type === "free") {
            fetchMenuCount();  // Appeler le comptage des menus si l'utilisateur a un abonnement "free"
        }
    }, [subscriptionExists, subscriptionInfos]);


    // Dans l'effet useEffect pour la récupération des menus
    useEffect(() => {
        const fetchAllMenus = async () => {
            try {
                const data = await getMenuByUser();
                if (data && data.length > 0) {
                    setMenusData(data);
                } else {
                    setMenusData([]); // Si aucun menu n'est trouvé
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des menus:", error);
                // On retire le toast.error ici pour ne pas alerter l'utilisateur en cas d'absence de menus
            }
        };
        fetchAllMenus();
    }, []);


    //Handle request sort
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Handle change in page number
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //Handle Number of rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //Function to sort data
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    // Function for sorting comparison
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    // Function for descending comparison
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    // MENU - Sorting the data
    const sortedMenus = stableSort(menusData, getComparator(order, orderBy));

    //Handle value's changes in search input
    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    //Filtering the menus based on the search term
    const filteredMenus = sortedMenus.filter(menu =>
        (menu.id?.toString() || "").includes(searchTerm) ||
        menu.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        menu.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCreateMenu = async (formData) => {
        try {
            const newMenuResponse = await createMenu(formData);
            toast.success('Menu créé avec succès');

            // Mise à jour des menus et du compteur de menus après la création
            const updatedMenus = await getMenuByUser();
            setMenusData(updatedMenus);

            // Mettre à jour le menuCount si un menu est ajouté
            setMenuCount(updatedMenus.length);

            setIsCreatePopupOpen(false);
        } catch (error) {
            toast.error('Erreur lors de la création du menu');
            console.error("Erreur lors de la création du menu", error);
        }
    };


    // This function handles the deletion of a menu
    const handleConfirmDelete = async (id) => {
        try {
            await deleteMenuById(id);
            toast.success('Menu supprimé avec succès');

            // Mettre à jour les menus après suppression
            const updatedMenus = await getMenuByUser();
            setMenusData(updatedMenus);

            // Mettre à jour le compteur de menus
            setMenuCount(updatedMenus.length);
        } catch (error) {
            console.error('Erreur lors de la suppression du menu :', error);
            toast.error('Erreur lors de la suppression du menu');
        }
    };

    const handleShareClick = async (menuId) => {
        const url = `${window.location.origin}/menu/${menuId}/shared`;
        try {
            await navigator.clipboard.writeText(url);
            toast.success('Lien copié dans le presse-papier');
        } catch (err) {
            console.error('Failed to copy: ', err);
            toast.error('Erreur lors de la génération du lien de partage');
        }
    };
    const roles = JSON.parse(localStorage.getItem('roles') || "[]");
    const isAdmin = roles.includes("admin");

    return (
        <>
            {/* -------------- SEARCH INPUT -------------- */}
            <input
                placeholder="Rechercher"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ margin: '10px', width: '100%', backgroundColor: "transparent", padding: "1rem", border: "1px solid #D5D5D5", borderRadius: "5px" }}
            />

            {/* -------------- ADD NEW MENU -------------- */}
            <div style={{ textAlign: "right", marginRight: ".2rem", marginTop: "2rem", marginBottom: "1rem" }}>
                <div style={{ textAlign: "right", marginRight: ".2rem", marginTop: "2rem", marginBottom: "1rem" }}>
                    {(isAdmin || (subscriptionExists && subscriptionInfos.subscription_type !== "free") || (subscriptionExists && subscriptionInfos.subscription_type === "free" && menuCount === 0)) ? (
                        <PopupAddMenu
                            trigger={
                                <ButtonComponent
                                    className="btn-add btn-add-product-table"
                                    type="button"
                                    text="+ Ajouter un menu"
                                    isOpen={isCreatePopupOpen}
                                />
                            }
                            onClose={() => setIsCreatePopupOpen(false)}
                            onCreate={handleCreateMenu}
                            title="Ajouter un menu"
                            message="Message de confirmation"
                        />
                    ) : (
                        <Tooltip
                            color="danger"
                            size="sm"
                            variant="outlined"
                            title="L'abonnement 'free' ne permet de créer qu'un seul menu"
                        >
                            <Button
                                className="btn-upgrade"
                                color="danger"
                                size="sm"
                                onClick={() => navigate('/account/subscriptions')}
                            >
                                Mettre à niveau l'abonnement
                            </Button>
                        </Tooltip>
                    )}
                </div>

            </div>

            {/* -------------- MENUS TABLE OR MESSAGE -------------- */}
            {menusData.length === 0 ? (
                <Typography variant="h6" component="div" style={{ textAlign: "center", marginTop: "2rem" }}>
                    Vous n'avez pas encore de menu.
                </Typography>
            ) : (
                <Paper style={{ overflow: "hidden" }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" style={{ maxWidth: "650px", minWidth: "650px" }} >
                            <TableHead className='table-head'>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, fontSize: "13px" }}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredMenus
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow style={{ fontSize: 13 }} hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ textAlign: "left", fontSize: 13, maxWidth: 150, minWidth: 20 }}>
                                                        {column.id === 'name' ?
                                                            <Link
                                                                to={`/account/menumaker/${row.id}`}
                                                                style={{ textDecoration: 'underline', color: '#006d26', fontWeight: 'bold' }}
                                                                className="recipes-title"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {value}
                                                            </Link> :
                                                            column.id === 'status' ? (
                                                                <Chip>{value}</Chip>
                                                            ) :
                                                                column.id === 'createdAt' ? dayjs(value).format('DD/MM/YYYY') :
                                                                    column.id === 'price' && typeof value === 'number' ? value.toFixed(1) + "€" :
                                                                        column.id === 'portion_price' ? (
                                                                            row.price && row.portions ? (row.price / row.portions).toFixed(1) + "€" : "-"
                                                                        ) :
                                                                            column.id === 'share' ? (
                                                                                <ShareIcon
                                                                                    style={{ width: 15, cursor: 'pointer' }} // Add cursor pointer for better UX
                                                                                    onClick={() => handleShareClick(row.id)}
                                                                                />
                                                                            ) : column.id === 'delete' ? (
                                                                                <PopupConfirm
                                                                                    trigger={
                                                                                        <IconButton style={{ fontSize: '16 !important' }}>
                                                                                            <TiDeleteOutline style={{ width: 15, color: "red" }} />
                                                                                        </IconButton>
                                                                                    }
                                                                                    onConfirm={() => handleConfirmDelete(row.id)}
                                                                                    message={`Êtes-vous sûr de vouloir supprimer le menu : ${row.name} ?`}
                                                                                />
                                                                            ) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={menusData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
        </>
    );
}

const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'status', label: 'Statut', minWidth: 100 },
    { id: 'name', label: 'Nom du menu', minWidth: 200 },
    { id: 'createdAt', label: 'Date de création', minWidth: 80 },
    { id: 'price', label: 'Coût du menu', minWidth: 150 },
    { id: 'portions', label: 'Portions', minWidth: 50, align: 'left' },
    { id: 'portion_price', label: 'Prix/portion', minWidth: 150, align: 'left' },
    { id: 'share', label: '', minWidth: 50, align: 'center' },
    { id: 'delete', label: '', minWidth: 50, align: 'center' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-head": {
        backgroundColor: "rgb(50, 89, 62)",
        borderColor: "rgb(50, 89, 62)",
        color: theme.palette.common.white,
        fontWeight: "bold",
        cursor: "pointer",
    },
    "&.MuiTableCell-body": {
        fontSize: 12,
    },
}));
