// MenuMaker.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Component
import PopupMenu from '../../../../components/elements/popup/popup-menus/PopupMenu';
import SharedMenuRecipesCard from '../../../../components/admin-dashboard/menus/SharedMenuRecipesCard';
import RecipeCard from '../../../../components/recipes/RecipeCard';


// API
import { getMenuById, updateMenuById } from '../../../../api/menuAPI';
import { getAllRecipes, fetchRecipeById } from '../../../../api/recipesAPI';
import { addRecipesToMenu, getRecipesByMenuId, deleteRecipesMenuById } from '../../../../api/menuRecipesAPI'

//toast
import toast from 'react-hot-toast';

//MUI
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

// Styles
import '../Menu.css';

function SharedMenu() {
    const { id } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Local States
    const [openMenuPopup, setOpenMenuPopup] = useState(false);
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [menuInfos, setMenuInfos] = useState(null);
    const [recipesIdList, setRecipesIdList] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [recipesList, setRecipesList] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);

    //Fetch Menu and Recipes Infos 
    useEffect(() => {
        const fetchMenuInfos = async () => {
            try {
                const data = await getMenuById(id);
                setMenuInfos(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations du menu:", error);
                toast.error(`Erreur lors de la récupération des informations du menu`);
            }
        };
        const fetchRecipes = async () => {
            try {
                const fetchedRecipes = await getAllRecipes();
                setRecipes(fetchedRecipes);

            } catch (error) {
                console.error("Erreur lors de la récupération des recettes:", error);
                toast.error(`Erreur lors de la récupération des recettes`);
            }
        };
        fetchMenuInfos();
        fetchRecipes();
    }, [id]);


    //Fetch Recipes linked to the menu
    useEffect(() => {
        const menuRecipes = async () => {
            try {
                const fetchedMenuRecipes = await getRecipesByMenuId(id);
                setRecipesIdList(fetchedMenuRecipes);

            } catch (error) {
                console.error(`Erreur lors de la récupération des recettes du menu ${id}`, error);
                toast.error(`Erreur lors de la récupération des recettes du menu ${id}`);
            }
        }
        menuRecipes();
    }, [id]);


    //Fetch Recipe's details
    useEffect(() => {
        const recipeDetails = async () => {
            try {
                const fetchedRecipeDetails = await Promise.all(
                    recipesIdList.map(async (recipeId) => {
                        return await fetchRecipeById(recipeId.recipe_id);
                    })
                );
                console.log("Fetched Recipe Details:", fetchedRecipeDetails); // Ajoutez ceci
                const validRecipes = fetchedRecipeDetails.filter(recipe => recipe !== null && recipe !== undefined);
                setRecipesList(validRecipes);
                console.log("Filtered Recipes List:", recipesList);

            } catch (error) {
                console.error(`Erreur lors de la récupération des détails de la recettes du menu`, error);
                toast.error(`Erreur lors de la récupération des détails de la recettes du menu `);
            }
        };
    
        if (recipesIdList.length > 0) {
            recipeDetails();
        }
    }, [id, recipesIdList]);


    //Select Recipe to add in the Menu
    const handleRecipeSelect = (recipeIds) => {
        setSelectedRecipes([...selectedRecipes, ...recipeIds]);
    };

    //Add Recipe to Menu
    const handleAddRecipesToMenu = async (selectedRecipeIds) => {
        try {
            const menuRecipesData = {
                menu_id: id,
                recipe_ids: selectedRecipeIds,
            };

            await addRecipesToMenu(menuRecipesData);
            const newRecipesIdList = [...recipesIdList, ...selectedRecipeIds.map(recipeId => ({ recipe_id: recipeId }))];
            setRecipesIdList(newRecipesIdList);

            const newRecipesDetails = await Promise.all(selectedRecipeIds.map(async (recipeId) => {
                return await fetchRecipeById(recipeId);
            }));

            setRecipesList([...recipesList, ...newRecipesDetails]);
            updateTotalPrice();
        } catch (error) {
            console.error("Erreur lors de l'ajout des recettes au menu:", error);
        }
    };


    const updateTotalPrice = async () => {
        try {
            const recipesDetails = await getRecipesByMenuId(id);
            const totalPriceValue = recipesDetails.reduce((acc, recipe) => {
                acc += recipe.price || 0;
                return acc;
            }, 0);

            const totalPortions = recipesDetails.reduce((acc, recipe) => {
                acc += recipe.portions || 0;
                return acc;
            }, 0);



            const totalPrice = parseFloat(totalPriceValue.toFixed(2));
            setTotalPrice(totalPrice);

            const updatedMenu = { price: totalPrice, portions: totalPortions };
            await updateMenuById(id, updatedMenu);
        } catch (error) {
            console.error("Erreur lors de la mise à jour du prix total:", error);
        }
    };






    //Delete Recipe
    const handleDeleteRecipe = async (recipeId) => {
        try {
            await deleteRecipesMenuById(id, recipeId);

            const newRecipesIdList = recipesIdList.filter(item => item.recipe_id !== recipeId);
            setRecipesIdList(newRecipesIdList);

            const newRecipesList = recipesList.filter(recipe => recipe.id !== recipeId);
            setRecipesList(newRecipesList);

            updateTotalPrice();
            toast.success(`La recette supprimée du menu ${menuInfos?.name} `);
        } catch (error) {
            console.error("Erreur lors de la suppression de la recette du menu:", error);
            toast.error(`Erreur lors de la suppression de la recette du menu`);
        }
    };

    console.log("Recipes List Before Filtering:", recipesList);
    return (
        <div className="menu">
            <h1>{menuInfos?.name}</h1>

            <div className="menu-add">


            </div>

            {
                recipesList.length > 0 && (
                    <div>
                        {recipesList.filter(recipe => recipe?.partId === 1).length > 0 && (
                            <section>
                                <h2>Entrées</h2>
                                <div className="divison"></div>
                                <div className='recipes_list'>
                                    {recipesList.filter(recipe => recipe?.partId === 1).map(recipe => (
                                       <RecipeCard
                                       key={recipe.id}
                                       recipe={recipe}
                                       isListContext={true}
                                       menuId={id}
                                       onDelete={handleDeleteRecipe}
                                       updateTotalPrice={updateTotalPrice}
                                       className="menu-recipe-card"
                                   />
                                    ))}
                                </div>
                            </section>
                        )}
                        {recipesList.filter(recipe => recipe?.partId === 2).length > 0 && (

                            <section >
                                <h2>Plats</h2>
                                <div className="divison"></div>
                                <div className='recipes_list' >
                                    {recipesList.filter(recipe => recipe?.partId === 2).map(recipe => (

                                        <RecipeCard
                                        key={recipe.id}
                                        recipe={recipe}
                                        isListContext={true}
                                        menuId={id}
                                        onDelete={handleDeleteRecipe}
                                        updateTotalPrice={updateTotalPrice}
                                        className="menu-recipe-card"
                                        />
                                    ))}
                                </div>
                            </section>
                        )}

                            {recipesList.filter(recipe => recipe?.partId === 3).length > 0 && (

                            <section >
                                <h2>Desserts</h2>
                                <div className="divison"></div>
                                <div className='recipes_list' >
                                    {recipesList.filter(recipe => recipe?.partId === 3).map(recipe => (

                                        <RecipeCard
                                        key={recipe.id}
                                        recipe={recipe}
                                        isListContext={true}
                                        menuId={id}
                                        onDelete={handleDeleteRecipe}
                                        updateTotalPrice={updateTotalPrice}
                                        className="menu-recipe-card"
                                        />
                                    ))}
                                </div>
                            </section>
                        )}

                        {recipesList.filter(recipe => recipe?.partId === 4).length > 0 && (

                            <section>
                                <h2>Boissons</h2>
                                <div className="divison"></div>
                                <div className='recipes_list'>
                                    {recipesList.filter(recipe => recipe?.partId === 4).map(recipe => (

                                <RecipeCard
                                key={recipe.id}
                                recipe={recipe}
                                isListContext={true}
                                menuId={id}
                    
                                className="menu-recipe-card"
                                />
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                )
            }
        </div >
    );
}

export default SharedMenu;
