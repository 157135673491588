import { useState, useEffect } from 'react';
import { getApiHeaders } from './apiHeaders';
import apiInstance from "./api";


/**
 * Fetches all parts from the server.
 * @returns {Promise} A promise that resolves to an array of part objects.
 */
export const fetchAllParts = async () => {
    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/parts/list-all`);

        // Map over each part
        const filteredParts = response.data.map(part => {
            return {
                ...part,
                recipes: part.recipes.filter(recipe => recipe.visibility === true)
            }
        });

        return filteredParts;
    } catch (error) {
        console.error("Erreur lors de la récupération des parts:", error);
        throw error;
    }
};



/**
 * Fetches all recipe pictures from the server.
 * @returns {Promise} A promise that resolves to an array of recipe picture objects.
 */
export const fetchAllRecipePictures = async () => {
    const headers = {
        "Access-Control-Allow-Origin": "*"
    };

    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/pictures/get-all`);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la récupération des images des recettes:", error);
        throw error;
    }
};




/**
 * Custom hook that fetches all parts from the server.
 * @returns {Object} An object containing the parts, loading state, and error state.
 */
export const useParts = () => {
    const [loading, setLoading] = useState(true);
    const [parts, setParts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        /**
         * Fetches all parts from the server and sets the state accordingly.
         * Also associates pictures to the respective recipes.
         */
        const fetchPartsAndPictures = async () => {
            try {
                const partsData = await fetchAllParts();
                const picturesData = await fetchAllRecipePictures();

                // Map through the parts and then their recipes to associate pictures
                const augmentedParts = partsData.map(part => {
                    return {
                        ...part,
                        recipes: part.recipes.map(recipe => {
                            const recipePicture = picturesData.find(picture => picture.recipe_id === recipe.id);
                            return {
                                ...recipe,
                                picture: recipePicture ? recipePicture.path : null
                            };
                        })
                    };
                });

                setParts(augmentedParts);
                setLoading(false);
            } catch (err) {
                console.error("An error occurred:", err);
                setError(err);
                setLoading(false);
            }
        };

        fetchPartsAndPictures();
    }, []);

    return { parts, loading, error };
};



/**
 * Synchronizes the available quantity of recipes.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const synchFridgeQuantity = async () => {
    try {
        const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/recipes/update/quantity-available`, {
            '_method': 'put'
        });
        return response.data;
    } catch (error) {
        console.error("Error synchronizing quantities", error);
        throw error;
    }
};


/**
 * Retrieves all recipes.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const getAllRecipes = async () => {
    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipes/get-all`);

        return response.data;

    } catch (error) {
        console.error("Erreur lors de la récupération de toutes les recettes", error);
        throw error;
    }
};


/**
 * Updates a recipe by id.
 * @param {string} id - The id of the recipe to update.
 * @param {Object} updatedRecipeData - The updated recipe data.
 * @param {string} updatedRecipeData.name - The name of the recipe.
 * @param {string} updatedRecipeData.description - The description of the recipe.
 * @param {number} updatedRecipeData.price - The price of the recipe.
 * @param {string} updatedRecipeData.barcode - The barcode of the recipe.
 * @param {string} updatedRecipeData.partId - The part id of the recipe.
 * @param {string} updatedRecipeData.visibility - The visibility of the recipe.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const updateRecipeById = async (id, updatedRecipeData) => {

    try {
        const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/recipes/update/${id}`, updatedRecipeData, { headers: getApiHeaders() });

        return response.data;
    } catch (error) {
        console.error("Erreur lors de la modification de la recette", error);
        throw error;
    }
};



/**
 * Creates a recipe.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const createRecipe = async (formData) => {
    const visibilityBool = formData.visibility === '1';

    const newRecipe = {
        name: formData.name,          // Le champ 'name' est directement une chaîne
        description: formData.description,
        price: formData.price,
        barcode: formData.barcode,
        partId: formData.partId,
        prep_time: formData.prep_time,
        steps: formData.steps,
        visibility: visibilityBool,
        user_id: formData.user_id,
    };

    try {
        const response = await apiInstance.post(
            `${process.env.REACT_APP_API_URL}/recipes/create`,
            newRecipe,
            { headers: getApiHeaders() }
        );

        return response;

    } catch (error) {
        console.error("Erreur lors de la création de la recette", error);
        throw error;
    }
};



/**
 * Deletes a recipe by id.
 * @param {string} id - The id of the recipe to delete.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const deleteRecipeById = async (id) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    try {
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": "fr",
            "x-access-token": token,
        };

        const response = await apiInstance.delete(`${process.env.REACT_APP_API_URL}/recipes/delete/${id}`, { headers });
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la suppression de la recette", error);
        throw error;
    }
};

/**
 * Uploads an image for a recipe.
 * @param {string} recipeId - The id of the recipe.
 * @param {File} imageFile - The image file to upload.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const uploadRecipeImage = async (recipeId, imageFile) => {
    try {
        const formData = new FormData();
        formData.append("recipeId", recipeId);
        formData.append("image", imageFile);

        console.log("Uploading image with FormData:", {
            recipeId,
            imageFile: imageFile.name,
        });

        const response = await apiInstance.post(
            `${process.env.REACT_APP_API_URL}/pictures/upload`,
            formData,
            { headers: getApiHeaders() }
        );
        return response.data;
    } catch (error) {
        console.error("Erreur lors de l'upload de l'image de la recette:", error.response?.data || error.message);
        throw error;
    }
};


/**
 * Fetches a recipe by id.
 * @param {string} recipeId - The id of the recipe to fetch.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const fetchRecipeById = async (recipeId) => {
    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
    };
    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipes/get/${recipeId}`,
            { headers: headers }
        );

        return response.data;
    } catch (error) {
        console.error(`Erreur lors de la récupération de la recette ${recipeId} :`, error);
        throw error;
    }
};


/**
 * Fetches a recipe by userId.
 * @param {string} recipeId - The id of the user to fetch.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const fetchRecipeByUserId = async () => {

    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    const userId = JSON.parse(localStorage.getItem("user"))?.id;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
        "x-access-token": token,
    };
    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipes/list/${userId}`,
            { headers: headers }
        );

        return response.data;
    } catch (error) {
        console.error(`Erreur lors de la récupération de la recette pour l'user ${userId} :`, error);
        throw error;
    }
};


export const updateRecipeImage = async (recipeId, imageFile) => {
    try {
        const formData = new FormData();
        formData.append("image", imageFile);

        const response = await apiInstance.put(
            `${process.env.REACT_APP_API_URL}/pictures/update/${recipeId}`,
            formData,
            { headers: getApiHeaders() }
        );

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error("Erreur lors de la mise à jour de l'image de la recette:", error.response.data);
            throw error.response.data;

        } else if (error.request) {
            console.error("Aucune réponse reçue lors de la mise à jour de l'image de la recette");
            throw new Error("Aucune réponse reçue lors de la mise à jour de l'image de la recette");

        } else {
            console.error("Erreur lors de la configuration de la requête pour la mise à jour de l'image de la recette:", error.message);
            throw new Error("Erreur lors de la configuration de la requête pour la mise à jour de l'image de la recette");
        }
    }
};

export const countRecipeByUserId = async (userId) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    try {
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": "fr",
            "x-access-token": token,
        };

        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/recipes/count/${userId}`, { headers });
        return response.data;
    } catch (error) {
        console.error(`Erreur lors du compte des recettes pour l'user ${userId}`, error);
        throw error;
    }
};
