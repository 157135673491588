import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    Autocomplete,
    TextField,
    Pagination,
    CircularProgress,
    Stack,
    Button,
    Typography,
} from "@mui/material";
import { fetchRecipeByUserId, fetchAllRecipePictures } from "../../../../api/recipesAPI";
import { fetchDataForRecipe } from "../../../../api/stockAPI";
import toast from "react-hot-toast";
import "./MobileRecipe.css";
import RecipeCard from "../../../recipes/RecipeCard";


function MobileRecipeList() {
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const roles = JSON.parse(localStorage.getItem("roles") || "[]");
    const isAdmin = roles.includes("admin");

    const [recipes, setRecipes] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recipesLoading, setRecipesLoading] = useState(true);
    const [filterLoading, setFilterLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const itemsPerPage = 5;
    const canAddRecipe =
        isAdmin || (subscriptionExists && subscriptionInfos?.subscription_type !== "free");

    const fetchRecipesWithDetails = async () => {
        try {
            setRecipesLoading(true);
            const [recipesData, picturesData] = await Promise.all([
                fetchRecipeByUserId(),
                fetchAllRecipePictures(),
            ]);

            const recipesWithDetails = recipesData.map((recipe) => {
                const picture = picturesData.find((pic) => pic.recipe_id === recipe.id);
                return {
                    ...recipe,
                    imageUrl: picture ? `${process.env.REACT_APP_API_URL}${picture.path}` : null,
                    linkedProducts: [],
                };
            });

            setRecipes(recipesWithDetails);
            fetchIngredientsForFilter(recipesWithDetails);
        } catch (error) {
            console.error("Erreur lors de la récupération des recettes :", error);
            setHasError(true);
        } finally {
            setRecipesLoading(false);
        }
    };

    const fetchIngredientsForFilter = async (recipes) => {
        try {
            setFilterLoading(true);

            const detailedRecipes = await Promise.all(
                recipes.map(async (recipe) => {
                    try {
                        const recipeDetails = await fetchDataForRecipe(recipe.id);
                        return {
                            ...recipe,
                            linkedProducts: recipeDetails.linkedProducts || [],
                            allProducts: recipeDetails.allProducts || [],
                        };
                    } catch (error) {
                        console.warn(`Error fetching details for recipe ID: ${recipe.id}`);
                        return recipe;
                    }
                })
            );

            setRecipes(detailedRecipes);

            const allIngredients = detailedRecipes.flatMap((recipe) => recipe.allProducts || []);
            const uniqueIngredients = [...new Set(allIngredients.map((item) => item.label))];
            setIngredientsList(uniqueIngredients);
        } catch (error) {
            console.error("Erreur lors de la récupération des ingrédients :", error);
        } finally {
            setFilterLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipesWithDetails();
    }, []);

    useEffect(() => {
        setCurrentPage(1); // Réinitialiser la pagination lorsque les filtres changent
    }, [searchTerm, selectedIngredients]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const filteredRecipes = recipes.filter((recipe) => {
        const searchTermLower = searchTerm.toLowerCase();
        const matchesSearchTerm =
            !searchTerm ||
            recipe.name.toLowerCase().includes(searchTermLower) ||
            recipe.description.toLowerCase().includes(searchTermLower);

        const containsExcludedIngredients = selectedIngredients.some((excluded) =>
            recipe.linkedProducts?.some((product) => product.productLabel === excluded)
        );

        return matchesSearchTerm && !containsExcludedIngredients;
    });

    const paginatedRecipes = filteredRecipes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <h2> Mes recettes Mobiles</h2>
                <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    {canAddRecipe ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => toast.success("Ajouter une recette !")}
                        >
                            + Ajouter une recette
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                                window.open(
                                    "https://billing.stripe.com/p/login/test_3cs9EfeWEfql7ZKeUU",
                                    "_blank"
                                )
                            }
                        >
                            Mettre à niveau l'abonnement
                        </Button>
                    )}
                </div>
                <TextField
                    className="search"
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: "100%", marginBottom: "1rem" }}
                />
                <Autocomplete
                    className="search"
                    multiple
                    options={ingredientsList}
                    value={selectedIngredients}
                    onChange={(event, newValue) => setSelectedIngredients(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Exclure les ingrédients..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {filterLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    noOptionsText="Aucun ingrédient disponible"
                />
            </div>

            <div className="mobile-recipe-list">
                {recipesLoading ? (
                    <CircularProgress sx={{ color: "darkgreen" }} />
                ) : hasError ? (
                    <Typography variant="body2" color="error">
                        Une erreur est survenue lors du chargement des recettes.
                    </Typography>
                ) : paginatedRecipes.length > 0 ? (
                    paginatedRecipes.map((recipe) => (
                        <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                            <RecipeCard
                                recipe={recipe}
                                isListContext={true} // Indique que le contexte est une liste
                            />
                        </Link>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        Aucun résultat trouvé.
                    </Typography>
                )}
            </div>

            <div className="pagination">
                <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: "20px", marginBottom: "1rem" }}
                >
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section>
    );
}

export default MobileRecipeList;
