import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { fetchAllAllergies } from "../../../../api/allergiesAPI";
import toast from 'react-hot-toast';

// Styles
import './PopupCreate.css';

/**
 * 1) Fonction de normalisation
 * Met le nom en minuscule, retire les accents,
 * et supprime un éventuel 's' ou 'es' final pour gérer le singulier/pluriel.
 */
function normalizeProductName(name) {
  let normalized = name.trim().toLowerCase();

  // Retirer les accents (ex : "pêches" => "peches")
  normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Retirer le 's' ou 'es' final (ex: "tomates" => "tomate")
  // Note : c'est une simplification (ex: "chevaux" => "chevau"),
  // à personnaliser pour d'autres cas.
  normalized = normalized.replace(/(es|s)$/i, "");

  return normalized;
}

function PopupCreate({ trigger, onCreate, products = [] }) {
  // État local du formulaire
  const [formData, setFormData] = useState({
    name: '',
    price: 0,
    totalQuantity: 0,
    unitType: 'g',   // On force l'unité en Grammes
    category: '',
    label: '',
  });

  const [allergiesOptions, setAllergiesOptions] = useState([]);
  const [selectedAllergen, setSelectedAllergen] = useState(null);
  const [errors, setErrors] = useState({});

  // État pour gérer les suggestions
  const [suggestions, setSuggestions] = useState([]);

  // 2) Catégories et labels
  const categories = [
    { value: 'Fruits et Légumes', label: 'Fruits et Légumes' },
    { value: 'Conserves', label: 'Conserves' },
    { value: 'Epices et Condiments', label: 'Epices et Condiments' },
    { value: 'Céréales et Féculents', label: 'Céréales et Féculents' },
    { value: 'Boissons', label: 'Boissons' },
    { value: 'Huiles et matières grasses', label: 'Huiles et matières grasses' },
    { value: 'Légumineuses', label: 'Légumineuses' },
    { value: 'Sucreries snacks', label: 'Sucreries snacks' },
    { value: 'Oléagineux et Fruits secs', label: 'Oléagineux et Fruits secs' },
    { value: 'Aliments transformés', label: 'Aliments transformés' },
  ];

  const labels = [
    { value: '', label: 'Aucun' },
    { value: 'bio', label: 'Bio' },
    { value: 'local', label: 'Local' },
    { value: 'national', label: 'National' },
  ];

  // 3) Récupération des allergies (API)
  useEffect(() => {
    const fetchAllergiesData = async () => {
      try {
        const allergies = await fetchAllAllergies();
        const allergiesOptionsData = allergies.map(allergy => ({
          value: allergy.id,
          label: allergy.name,
        }));
        setAllergiesOptions(allergiesOptionsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des allergies :", error);
      }
    };
    fetchAllergiesData();
  }, []);

  // 4) Validation du formulaire
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Le nom du produit est obligatoire.";
    }
    if (!formData.category) {
      newErrors.category = "Veuillez sélectionner une catégorie.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * 5) Gérer la saisie du nom pour afficher des suggestions
   * À chaque frappe, on :
   *   - met à jour le champ name,
   *   - normalise le texte,
   *   - trouve les produits existants « proches »,
   *   - affecte le résultat à suggestions.
   */
  const handleChangeName = (value) => {
    setFormData({ ...formData, name: value });

    // Normaliser l'entrée pour comparer
    const inputNormalized = normalizeProductName(value);

    if (!value.trim()) {
      // Si l'utilisateur efface tout, on efface aussi les suggestions
      setSuggestions([]);
      return;
    }

    // Filtrer la liste des produits existants selon la similarité
    const matchedProducts = products.filter((product) => {
      const existingNormalized = normalizeProductName(product.name);
      return existingNormalized.includes(inputNormalized);
    });

    // Limiter le nombre de suggestions (ex: 5)
    setSuggestions(matchedProducts.slice(0, 5));
  };

  /**
   * 6) Soumission du formulaire
   */
  const handleSubmit = (event, close) => {
  event.preventDefault();

  if (!validateForm()) {
    toast.error("Veuillez corriger les erreurs dans le formulaire.");
    return;
  }

  const normalizedFormData = {
    ...formData,
    unitType: formData.unitType.toLowerCase(), // Normaliser l'unité
  };

  const newProductNameNormalized = normalizeProductName(normalizedFormData.name);
  const isApproxDuplicate = products.some((product) => {
    return normalizeProductName(product.name) === newProductNameNormalized;
  });

  if (isApproxDuplicate) {
    toast.error("Un produit similaire existe déjà.");
    return;
  }

  onCreate(normalizedFormData, selectedAllergen);
  close();
  resetForm();
};

  // 7) Réinitialiser le formulaire
  const resetForm = () => {
    setFormData({
      name: '',
      price: 0,
      totalQuantity: 0,
      unitType: 'g',
      category: '',
      label: '',
    });
    setErrors({});
    setSelectedAllergen(null);
    setSuggestions([]);
  };

  return (
    <Popup
      trigger={trigger}
      position="right center"
      modal
      contentStyle={{ zIndex: 99999 }}
    >
      {(close) => (
        <div className="popup-create-content flex-center">
          <div className="popup-title">
            <h1>Nouveau produit</h1>
          </div>

          <div className="popup-create-form">
            <form onSubmit={(e) => handleSubmit(e, close)}>
              
              {/* Nom du produit */}
              <label className="create-product-label">
                Nom du produit <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`popup-create-name ${errors.name ? 'input-error' : ''}`}
                placeholder="Nom du produit"
                value={formData.name}
                onChange={(e) => handleChangeName(e.target.value)}
                required
              />
              {errors.name && <p className="error-text">{errors.name}</p>}

              {/* Suggestions (si présentes) */}
              {suggestions.length > 0 && (
                <div className="suggestions-container">
                  <p><strong>Produits déjà existants :</strong></p>
                  <ul className="suggestions-list">
                    {suggestions.map((product) => (
                      <li key={product.id}>{product.name}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Prix */}
              <label className="create-product-label">Prix</label>
              <input
                type="number"
                className="popup-create-price"
                placeholder="Prix du produit"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              />

              {/* Quantité */}
              <label className="create-product-label">Quantité en stock</label>
              <input
                type="number"
                className="popup-create-totalQty"
                placeholder="Quantité Totale"
                value={formData.totalQuantity}
                onChange={(e) => setFormData({ ...formData, totalQuantity: e.target.value })}
              />

              {/* Unité */}
              <label className="create-product-label">Unité</label>
              <select
                className="edit-input"
                value={formData.unitType}
                disabled // on empêche la modification pour l'instant
              >
                <option value="G">Grammes (G)</option>
              </select>

              {/* Catégorie */}
              <label className="create-product-label">
                Catégorie <span className="required">*</span>
              </label>
              <select
                className={`edit-input ${errors.category ? 'input-error' : ''}`}
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                required
              >
                <option value="" disabled hidden>
                  Sélectionnez une catégorie
                </option>
                {categories.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.category && <p className="error-text">{errors.category}</p>}

              {/* Label */}
              <label className="create-product-label">Label</label>
              <select
                className="edit-input"
                value={formData.label}
                onChange={(e) => setFormData({ ...formData, label: e.target.value })}
              >
                {labels.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              {/* Allergènes */}
              <label className="create-product-label">Allergènes</label>
              <select
                className="edit-input"
                value={selectedAllergen || ""}
                onChange={(e) => setSelectedAllergen(e.target.value || null)}
              >
                <option value="">Aucun</option>
                {allergiesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              {/* Boutons d'action */}
              <div className="popup-actions">
                <button type="submit" className="btn-full">
                  Créer
                </button>
                <button type="button" className="btn-border" onClick={close}>
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default PopupCreate;
