import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import RecipeHeader from "./RecipeHeader";
import RecipeImage from "./RecipeImage";
import RecipeNameForm from "./RecipeNameForm";
import RecipeDetailsContainer from "./RecipeDetailsContainer";
import toast from "react-hot-toast";
import {
    fetchRecipeById,
    fetchAllRecipePictures,
    updateRecipeById,
    updateRecipeImage,
} from "../../../api/recipesAPI";
import { fetchNutriFacts } from "../../../api/nutriFactsAPI";

const partIdLabels = {
    1: "1 — Entrées",
    2: "2 — Plats",
    3: "3 — Desserts",
    4: "4 — Boissons",
};

function RecipesDetails({ recipeId }) {
    const [recipe, setRecipe] = useState(null);
    const [nutriFacts, setNutriFacts] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [imageKey, setImageKey] = useState(0);

    const [editedFields, setEditedFields] = useState({
        name: "",
        price: "",
        barcode: "",
        partId: "",
        visibility: false,
        description: "",
        steps: "",
        prep_time: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [recipeData, allPictures, nutritionData] = await Promise.all([
                    fetchRecipeById(recipeId),
                    fetchAllRecipePictures(),
                    fetchNutriFacts(recipeId),
                ]);

                const picture = allPictures.find((pic) => pic.recipe_id === Number(recipeId));
                const url = picture ? `${process.env.REACT_APP_API_URL}${picture.path}` : null;

                setRecipe({ ...recipeData, imageUrl: url });
                setNutriFacts(nutritionData);

                setEditedFields({
                    name: recipeData.name || "",
                    price: recipeData.price != null ? recipeData.price.toString() : "",
                    barcode: recipeData.barcode || "",
                    partId: recipeData.partId != null ? recipeData.partId.toString() : "",
                    visibility: recipeData.visibility || false,
                    description: recipeData.description || "",
                    steps: recipeData.steps || "",
                    prep_time: recipeData.prep_time || "",
                });
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failed to load recipe details.");
            }
        };

        fetchData();
    }, [recipeId]);

    const handleEditClick = (field) => {
        setEditingField(field);
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const updatedRecipeData = {
                name: editedFields.name,
                price: parseFloat(editedFields.price),
                barcode: editedFields.barcode,
                partId: parseInt(editedFields.partId),
                visibility: editedFields.visibility,
                description: editedFields.description,
                steps: editedFields.steps,
                prep_time: editedFields.prep_time,
                portions: editedFields.portions
            };

            await updateRecipeById(recipeId, updatedRecipeData);

            setRecipe((prevRecipe) => ({ ...prevRecipe, ...updatedRecipeData }));
            setIsEditing(false);
            setEditingField(null);
            toast.success("Recipe updated successfully!");
        } catch (error) {
            console.error("Error saving recipe:", error);
            toast.error("Failed to update recipe.");
        }
    };

    const handleInputChange = (field, value) => {
        setEditedFields((prevFields) => ({
            ...prevFields,
            [field]: value,
        }));
    };

    const handleImageUpdate = async () => {
        try {
            if (!selectedImage) {
                console.error("Please select an image");
                return;
            }
            await updateRecipeImage(recipeId, selectedImage);
            setImageKey((prevKey) => prevKey + 1);
            toast.success("Image updated successfully!");
        } catch (error) {
            console.error("Error updating image:", error);
            toast.error("Failed to update image.");
        }
    };

    if (!recipe) {
        return <div style={{ textAlign: "center", marginTop: "2rem" }}>Loading recipe...</div>;
    }

    return (
        <section className="recipes-details container">
            <Helmet>
                <title>{recipe.name || "Loading..."}</title>
            </Helmet>
            <RecipeHeader
                recipe={recipe}
                isEditing={isEditing}
                nutriFacts={nutriFacts}
                onEditClick={() => handleEditClick("name")}
            />
            {isEditing && editingField === "name" && (
                <RecipeNameForm
                    recipe={recipe}
                    editedFields={editedFields}
                    onSaveClick={handleSave}
                    onInputChange={handleInputChange}
                />
            )}
            <RecipeImage
                key={imageKey}
                recipe={recipe}
                imageUrl={recipe.imageUrl}
                onImageUpdated={handleImageUpdate}
            />
            <RecipeDetailsContainer
                recipe={recipe}
                isEditing={isEditing}
                editingField={editingField}
                editedFields={editedFields}
                partIdLabels={partIdLabels}
                handleEditClick={handleEditClick}
                handleSaveClick={handleSave}
                handleInputChange={handleInputChange}
            />
        </section>
    );
}

export default RecipesDetails;
