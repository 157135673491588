import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

// Public Pages
import DeliveryZones from "../views/delivery-zones/DeliveryZones";
import Home from "../views/home/Home";
import Login from "../views/login/Login";
import MailResetPassword from "../views/reset-password/MailResetPassword";
import ResetPassword from "../views/reset-password/ResetPassword";
import Signup from "../views/signup/Signup";

// Client Dashboard
import ClientMobile from "../views/client-dashboard/ClientMobile";
import DashboardClient from "../views/client-dashboard/DashboardClient";
import ClientProfil from "../components/client-dashboard/ClientInfos";
import MobileClientProfil from "../components/client-dashboard/ClientInfos";
import ClientOrdersList from "../components/client-dashboard/ClientOrdersList";
import MobileOrderList from "../components/client-dashboard/MobileOrderList";
import ClientSubscription from "../components/client-dashboard/ClientSubscription";
import MobileClientSubscription from "../components/client-dashboard/ClientSubscription";
import GroupOrdersTracking from "../views/client-dashboard/GroupOrdersTracking";

// Admin Dashboard
import AdminDashboard from "../views/admin-dashboard/AdminDashboard";
import AllUsers from "../views/admin-dashboard/user-list/AllUsers";
import MobileAllUsers from "../components/admin-dashboard/user-list/MobileAllUsers";
import AllOrders from "../views/admin-dashboard/orders/AllOrders";
import MobileAllOrders from "../components/admin-dashboard/order-list/MobileAllOrders";
import AdminMobile from "../views/admin-dashboard/AdminMobile";

// Stock
import Stock from "../views/admin-dashboard/stock/Stock";

// Recipes
import Recipes from "../views/admin-dashboard/recipes/Recipes";
import MobileRecipeList from "../components/admin-dashboard/recipes/mobile/MobileRecipeList";
import RecipesInfos from "../views/admin-dashboard/recipes/recipe-infos/RecipesInfos";
import SharedRecipe from "../views/admin-dashboard/recipes/recipe-infos/SharedRecipe";

// Menu Maker
import MenuList from "../views/admin-dashboard/menu-maker/MenuList";
import MobileMenuList from "../components/admin-dashboard/menus/MobileMenuList";
import MenuMaker from "../views/admin-dashboard/menu-maker/menu/MenuMaker";
import SharedMenu from "../views/admin-dashboard/menu-maker/menu/SharedMenu";
import ShoppingList from "../components/admin-dashboard/menus/ShoppingList";
import PricingPage from "../views/suscriptions/PricingPage";

// Orders and Payments
import OrderValidation from "../views/order-validation/OrderValidation";
import Checkout from "../views/checkout/Checkout";
import Paygreen from "../views/paygreen/Paygreen";

// Error
import Error from "../views/error/Error";

// Private Route
import PrivateRoute from "./PrivateRoute";

function AppRoutes() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) && (max-width: 1024px)");

  return (
    <Routes>
      {/* ======= Public Routes ======= */}
      <Route path="/" element={<DeliveryZones />} />
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<MailResetPassword />} />
      <Route path="/reset-password/:id/:token" element={<ResetPassword />} />

      {/* =========================
          CLIENT DASHBOARD
      ========================== */}
      <Route
        path="/account"
        element={
          // Parent route strictement client
          isMobile ? (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminMobile}>
              <ClientMobile />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client"]} fallbackComponent={AdminDashboard}>
              <DashboardClient />
            </PrivateRoute>
          )
        }
      >
        {!isMobile ? (
          <>
            {/* Desktop client routes */}
            <Route path="profil" element={<ClientProfil />} />
            <Route path="my-orders" element={<ClientOrdersList />} />
            <Route path="subscriptions" element={<ClientSubscription />} />
            <Route path="recipes" element={<Recipes />} />
            <Route path="menulist" element={<MenuList />} />
            <Route path="stocks" element={<Stock />} />
          </>
        ) : (
          <>
            {/* Mobile client routes */}
            <Route path="profil" element={<MobileClientProfil />} />
            <Route path="my-orders" element={<MobileOrderList />} />
            <Route path="subscriptions" element={<MobileClientSubscription />} />
            <Route path="recipes" element={<Recipes />} />
            <Route path="menulist" element={<MobileMenuList />} />
            <Route path="stocks" element={<Stock />} />
          </>
        )}

        {/* Commun (client) */}
        <Route
          path="orders-tracking"
          element={
            <PrivateRoute rolesRequired={["client"]}>
              <GroupOrdersTracking />
            </PrivateRoute>
          }
        />
      </Route>

      {/* =========================
          MENUMAKER route MIXTE
      ========================== */}
      {/* On déclare /account/menumaker/:id en-dehors du parent, 
          pour autoriser "client" OU "admin"
          sans imposer la navbar client ou l'UI admin. 
      */}
      <Route
        path="/account/menumaker/:id"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminMobile}>
              <MenuMaker />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminDashboard}>
              <MenuMaker />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/menumaker/:id/shopping-list"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminMobile}>
              <ShoppingList />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminDashboard}>
              <ShoppingList />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/account/menumaker/pricing"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminMobile}>
              <PricingPage />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminDashboard}>
              <PricingPage />
            </PrivateRoute>
          )
        }
      />

      {/* =========================
          ADMIN-ONLY LISTS
      ========================== */}
      <Route
        path="/account/users-list"
        element={
          <PrivateRoute rolesRequired={["admin"]}>
            {isMobile ? <MobileAllUsers /> : <AllUsers />}
          </PrivateRoute>
        }
      />
      <Route
        path="/account/orders-list"
        element={
          <PrivateRoute rolesRequired={["admin"]}>
            {isMobile ? <MobileAllOrders /> : <AllOrders />}
          </PrivateRoute>
        }
      />

      {/* =========================
          RECIPES (géré client & admin ?)
      ========================== */}
      <Route
        path="/account/recipes/:id"
        element={
          <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminDashboard}>
            <RecipesInfos />
          </PrivateRoute>
        }
      />
      <Route
        path="/recipes/:id"
        element={
          <PrivateRoute rolesRequired={["client","admin"]} fallbackComponent={AdminDashboard}>
            <RecipesInfos />
          </PrivateRoute>
        }
      />
      <Route path="/recipes/:id/shared" element={<SharedRecipe />} />

      {/* ======= Menu Shared ======= */}
      <Route path="/menu/:id/shared" element={<SharedMenu />} />

      {/* =========================
          Orders & Payments
      ========================== */}
      <Route path="/order-validation" element={<OrderValidation />} />
      <Route path="/paygreen" element={<Paygreen />} />
      <Route
        path="/checkout"
        element={
          <PrivateRoute rolesRequired={["client","admin"]}>
            <Checkout />
          </PrivateRoute>
        }
      />

      {/* =========================
          ADMIN DASHBOARD
      ========================== */}
      <Route
        path="/admin"
        element={
          isMobile ? (
            <PrivateRoute rolesRequired={["admin"]} fallbackComponent={AdminMobile}>
              <AdminDashboard />
            </PrivateRoute>
          ) : (
            <PrivateRoute rolesRequired={["admin"]}>
              <AdminDashboard />
            </PrivateRoute>
          )
        }
      >
        {/* Sous-routes /admin (facultatif) */}
        <Route index element={<AllUsers />} />
        <Route path="menumaker/:id" element={<MenuMaker />} />
        <Route path="recipes/:id" element={<RecipesInfos />} />
        {/* etc. */}
      </Route>

      {/* ======= Fallback Route ======= */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default AppRoutes;
