/* Parts implementation are commented because there's a problem to fix in the backend 
parts logic. Once fixed, suppress commenting  */

//Components
//import React, { useEffect, useState } from 'react';
import RecipesNav from '../recipes-nav/RecipesNav'
import RecipesList from './RecipesList'


//Styles
import './Recipes.css'

function Recipes() {

    return (
        <>
            <RecipesNav />
            <RecipesList />
            
        </>
    )
}

export default Recipes;