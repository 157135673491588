import React, { useState } from 'react';

// Popup ReactJS
import Popup from 'reactjs-popup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// MUI components
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Icons
import { AiOutlineCloudUpload } from 'react-icons/ai';

// Toast
import toast from 'react-hot-toast';

// Styles
import './PopupRecipe.css';

function PopupRecipe({ trigger, onCreate }) {
    // Get userId from localStorage
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    const userRole = JSON.parse(localStorage.getItem("user"))?.roleId;
    const isAdmin = userRole === "admin"; // Adapter cette condition selon votre système de rôles


    // Local States
    const [imageName, setImageName] = useState("");
    const [checked, setChecked] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        price: "0.0",
        barcode: "",
        visibility: false,
        partId: "1",
        image: null,
        prep_time: "",
        steps: "",
        user_id: userId,
        portions: 1,
    });

    // Parts options
    const partsId = [
        { value: '1', label: 'Entrées' },
        { value: '2', label: 'Plats' },
        { value: '3', label: 'Desserts' },
        { value: '4', label: 'Boissons' },
    ];

    // Handle submit event
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event, close) => {
        event.preventDefault();
        setIsSubmitting(true); // Active l'état de soumission
    
        const updatedFormData = {
            ...formData,
            barcode: formData.barcode || "1234567890", // Valeur par défaut si vide
            price: formData.price || "1.1", // Valeur par défaut si vide
            name: String(formData.name),
        };
    
        try {
            if (typeof onCreate === "function") {
                await onCreate(updatedFormData);
                toast.success("Recette créée avec succès");
                // Réinitialiser le formulaire
                setFormData({
                    name: "",
                    description: "",
                    price: "0.0",
                    barcode: "",
                    visibility: false,
                    partId: "1",
                    image: null,
                    prep_time: "",
                    steps: "",
                    user_id: userId,
                    portions: 1,
                });
                setImageName("");
                setChecked(false);
                close(); // Fermer le popup
            } else {
                console.error("La prop 'onCreate' n'est pas une fonction.");
            }
        } catch (error) {
            console.error("Erreur lors de la création de la recette :", error);
            if (error.response?.status === 500) {
                toast.error("Erreur interne du serveur.");
            } else {
                toast.error("Une erreur est survenue.");
            }
        } finally {
            setIsSubmitting(false); // Réinitialise l'état de soumission
        }
    };
    

    // Handle image change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
        setImageName(file ? file.name : "");
    };

    // Handle switch change
    const handleToggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        setFormData({ ...formData, visibility: newValue ? 1 : 0 });
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            contentStyle={{ zIndex: 99999 }}
        >
            {(close) => (
                <div className="popup-confirm-content">
                    <div className="popup-title">
                        <h1>Nouvelle recette</h1>
                    </div>
                    <div className="popup-recipe-form">
                        <form onSubmit={(e) => handleSubmit(e, close)}>
                            <input
                                id="outlined-basic"
                                label="Nom de la recette *"
                                variant="outlined"
                                type="text"
                                className="popup-recipe-name"
                                placeholder="Nom de la recette *"
                                color="success"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData({ ...formData, name: e.target.value })
                                }
                                required
                            />
                            {isAdmin && (
                                    <>
                                        <input
                                            id="outlined-basic"
                                            label="Code-barre (optionnel)"
                                            variant="outlined"
                                            type="text"
                                            className="popup-recipe-barcode"
                                            placeholder="Code-barre"
                                            color="success"
                                            value={formData.barcode}
                                            onChange={(e) =>
                                                setFormData({ ...formData, barcode: e.target.value })
                                            }
                                        />
                                        <label htmlFor="price">Prix de la recette (€)</label>
                                        <input
                                            id="outlined-number"
                                            name="price"
                                            label="Prix de la recette (optionnel)"
                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="popup-recipe-price"
                                            placeholder="Prix"
                                            color="success"
                                            value={formData.price}
                                            onChange={(e) =>
                                                setFormData({ ...formData, price: e.target.value })
                                            }
                                        />
                                         <label htmlFor="switch" >Visibility </label>
                                        <Switch
                                            checked={checked}
                                            onChange={handleToggle}
                                            className="edit-btn"
                                            color="success"
                                            inputProps={{ 'aria-label': 'toggle visibility' }}
                                        />
                                    </>
                                )}
                            <input
                                id="outlined-textarea"
                                label="Description de la recette *"
                                placeholder="Description *"
                                color="success"
                                className="popup-recipe-description"
                                value={formData.description}
                                onChange={(e) =>
                                    setFormData({ ...formData, description: e.target.value })
                                }
                                multiline
                            />
                            
                            <label htmlFor="portions">Nombre de portions *</label>
                            <input
                                id="outlined-number"
                                name="portions"
                                label="Nombre de portions *"
                                type="number"
                                variant="outlined"
                                step="1"
                                min="1"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-recipe-portions"
                                placeholder="Portions * "
                                color="success"
                                value={formData.portions}
                                onChange={(e) =>
                                    setFormData({ ...formData, portions: e.target.value })
                                }
                            />
                            <div className="popup-recipe-part">
                            <Select
                                        value={formData.partId}
                                        onChange={(e) => setFormData({ ...formData, partId: e.target.value })}
                                        className="popup-recipe-partId"
                                        size="small"  // Réduit la taille du champ
                                        >
                                        {partsId.map((option) => (
                                            <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            sx={{
                                                backgroundColor: '#fff',        // couleur de fond par défaut des items
                                                color: '#1C4127',               // texte en vert
                                                '&:hover': {
                                                backgroundColor: '#1C4127',   // fond au hover
                                                color: '#fff',                // texte en blanc au hover
                                                },
                                            }}
                                            >
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                        </Select>

                            </div>
                            <label htmlFor="prep_time">Temps de préparation (min) * </label>
                            <input
                                id="outlined-number"
                                name="prep_time"
                                type="number"
                                variant="outlined"
                                step="1"
                                min="0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-recipe-price"
                                placeholder="Temps de préparation"
                                color="success"
                                value={formData.prep_time}
                                onChange={(e) =>
                                    setFormData({ ...formData, prep_time: e.target.value })
                                }
                                required
                            />
                            <ReactQuill
                                name="steps"
                                id="steps"
                                cols="30"
                                rows="10"
                                placeholder="Étapes de préparation"
                                value={formData.steps}
                                onChange={(value) =>
                                    setFormData({ ...formData, steps: value })
                                }
                            />
                            <label htmlFor="recipe-image" className="custom-file-upload btn-border btn-ms">
                                <AiOutlineCloudUpload />
                                <p>{imageName ? imageName : "Ajouter une image (optionnel) "}</p>
                                <input
                                    type="file"
                                    id="recipe-image"
                                    accept="image/*"
                                    className="custom-file-input"
                                    onChange={handleImageChange}
                                />
                            </label>
                            <div className="popup-actions flex-center">
                            <button type="submit" className="btn-full btn-sm" disabled={isSubmitting}>
                                {isSubmitting ? "Création en cours..." : "Créer"}
                            </button>
                                <button className="btn-border btn-sm" onClick={close}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupRecipe;
