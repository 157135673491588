import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Checkbox, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

import { getRecipesByMenuId } from '../../../api/menuRecipesAPI';
import { fetchRecipeById } from "../../../api/recipesAPI";

function ShoppingListTables({ productsInfos, menuInfos, updateShoppingListProduct }) {
    const [recipesPortions, setRecipesPortions] = useState({});
    const [recipesDetails, setRecipesDetails] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchRecipePortionsAndDetails = async () => {
            setLoading(true); // Début du chargement
            try {
                const recipes = await getRecipesByMenuId(menuInfos.id);
    
                const recipesPortionsResult = recipes.reduce((acc, recipe) => {
                    acc[recipe.recipe_id] = recipe.portions;
                    return acc;
                }, {});
                setRecipesPortions(recipesPortionsResult);
    
                const recipeDetailsPromises = recipes.map(async (recipe) => {
                    const recipeDetails = await fetchRecipeById(recipe.recipe_id);
                    return { recipeId: recipe.recipe_id, details: recipeDetails };
                });
    
                const resolvedDetails = await Promise.all(recipeDetailsPromises);
    
                const recipesDetailsResult = resolvedDetails.reduce((acc, { recipeId, details }) => {
                    acc[recipeId] = details;
                    return acc;
                }, {});
                setRecipesDetails(recipesDetailsResult);
            } catch (error) {
                console.error('Error fetching recipes for menuId', menuInfos.id, ':', error);
            } finally {
                setLoading(false); // Fin du chargement
            }
        };
    
        if (menuInfos.id) fetchRecipePortionsAndDetails();
    }, [menuInfos.id]);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004618',
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // Group products by category
    const groupedProducts = productsInfos.reduce((acc, product) => {
        if (!acc[product.category]) acc[product.category] = [];
        acc[product.category].push(product);
        return acc;
    }, {});

    // Calculate total cost and total products
    const calculateTotals = () => {
        let totalCost = 0;
        let totalProducts = 0;

        productsInfos.forEach(product => {
            const adjustedUnitQty = (product.unitQty / (recipesDetails[product.recipesId]?.portions || 1)) * (recipesPortions[product.recipesId] || 1);
            const pricePerGram = product.price / 1000;
            const productCost = adjustedUnitQty * pricePerGram;

            totalCost += isNaN(productCost) ? 0 : productCost;
            totalProducts++;
        });

        return { totalCost: totalCost.toFixed(2), totalProducts };
    };

    const { totalCost, totalProducts } = calculateTotals();

    return (
        loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress size={60} style={{ color: '#1C4127' }} />
            </div>
        ) : (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {/* Display total count and cost */}
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Nombre de produits : {totalProducts}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Coût total : {totalCost} €
                    </Typography>
                </div>
    
                <TableContainer>
                    <Table stickyHeader aria-label="shopping list table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Acheté</StyledTableCell>
                                <StyledTableCell>Nom du Produit</StyledTableCell>
                                <StyledTableCell align="right">Quantité ajustée</StyledTableCell>
                                <StyledTableCell align="right">Unités</StyledTableCell>
                                <StyledTableCell align="right">Prix/kg (€)</StyledTableCell>
                                <StyledTableCell align="right">Coût (€)</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(groupedProducts).map(([category, products]) => (
                                <React.Fragment key={category}>
                                    <TableRow>
                                        <StyledTableCell colSpan={6} style={{ textAlign: 'left', backgroundColor: '#017529', color: '#fff' }}>
                                            {category}
                                        </StyledTableCell>
                                    </TableRow>
                                    {products.map((product, index) => {
                                        const adjustedUnitQty = (product.unitQty / (recipesDetails[product.recipesId]?.portions || 1)) * (recipesPortions[product.recipesId] || 1);
                                        const pricePerGram = product.price / 1000;
                                        const productCost = adjustedUnitQty * pricePerGram;
    
                                        return (
                                            <TableRow key={index}>
                                                <StyledTableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={product.tobuy === false}
                                                        onChange={() => updateShoppingListProduct(product.id)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {product.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {adjustedUnitQty.toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {product.unitType}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {product.price.toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {productCost.toFixed(2)}
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    );
}

export default ShoppingListTables;
