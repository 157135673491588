import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import PopupMenu from '../../../../components/elements/popup/popup-menus/PopupMenu';
import RecipeCard from '../../../../components/recipes/RecipeCard';

// API
import { getMenuById } from '../../../../api/menuAPI';
import {
    addRecipesToMenu,
    getRecipesByMenuId,
    deleteRecipesMenuById
} from '../../../../api/menuRecipesAPI';
import { fetchRecipeById, fetchRecipeByUserId } from '../../../../api/recipesAPI';

// Toast
import toast from 'react-hot-toast';

// MUI
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

// Styles
import '../Menu.css';

function MenuMaker() {
    const { id } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // States
    const [openMenuPopup, setOpenMenuPopup] = useState(false);
    const [menuInfos, setMenuInfos] = useState(null);
    const [recipes, setRecipes] = useState([]);
    const [recipesList, setRecipesList] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    

    // Fetch menu info and recipes
    useEffect(() => {
        const fetchMenuInfos = async () => {
            try {
                const data = await getMenuById(id);
                console.log("Menu info:", data); // Vérifie les informations du menu récupérées
                setMenuInfos(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations du menu :", error);
                toast.error("Impossible de récupérer les informations du menu.");
            }
        };

        const fetchAvailableRecipes = async () => {
            try {
                const availableRecipes = await fetchRecipeByUserId();
                console.log("Available recipes:", availableRecipes); // Vérifie les recettes disponibles
                setRecipes(availableRecipes);
            } catch (error) {
                console.error("Erreur lors de la récupération des recettes disponibles :", error);
                toast.error("Impossible de récupérer les recettes disponibles.");
            }
        };

        const fetchMenuRecipes = async () => {
            try {
                const linkedRecipes = await getRecipesByMenuId(id);
                console.log("Linked recipes from menu API:", linkedRecipes);
        
                const detailedRecipes = await Promise.all(
                    linkedRecipes.map(async (recipe) => {
                        const detailedRecipe = await fetchRecipeById(recipe.recipe_id);
                        return {
                            ...detailedRecipe,
                            price: recipe.price, // Assurez-vous de fusionner correctement le prix
                            portions: recipe.portions, // Ajoutez les portions si nécessaire
                        };
                    })
                );
        
                console.log("Detailed recipes with merged data:", detailedRecipes);
        
                setRecipesList(detailedRecipes.filter((recipe) => recipe)); // Mise à jour des recettes
                setTotalPrice(
                    detailedRecipes.reduce((acc, recipe) => acc + (parseFloat(recipe.price) || 0), 0).toFixed(2) // Calcul immédiat du total
                );
            } catch (error) {
                console.error("Erreur lors de la récupération des recettes liées au menu :", error);
                toast.error("Impossible de récupérer les recettes liées au menu.");
            }
        };

        fetchMenuInfos();
        fetchAvailableRecipes();
        fetchMenuRecipes();
    }, [id]);

    // Add recipes to menu
    const handleAddRecipesToMenu = async (selectedRecipeIds) => {
        try {
            console.log("Ajout des recettes au menu avec les IDs :", selectedRecipeIds);
    
            await addRecipesToMenu({
                menu_id: id,
                recipe_ids: selectedRecipeIds,
            });
    
            const newRecipes = await Promise.all(
                selectedRecipeIds.map((recipeId) => fetchRecipeById(recipeId))
            );
    
            console.log("Nouvelles recettes ajoutées :", newRecipes);
    
            setRecipesList((prev) => [...prev, ...newRecipes.filter((r) => r)]);
            updateTotalPrice();
            toast.success("Recettes ajoutées au menu avec succès !");
        } catch (error) {
            console.error("Erreur lors de l'ajout des recettes au menu :", error);
            toast.error("Impossible d'ajouter des recettes au menu.");
        }
    };

    // Delete a recipe from the menu
    const handleDeleteRecipe = async (recipeId) => {
        try {
            console.log("Deleting recipe from menu, ID:", recipeId); // Vérifie l'ID de la recette supprimée
            await deleteRecipesMenuById(id, recipeId);
            setRecipesList((prev) => prev.filter((recipe) => recipe.id !== recipeId));
            updateTotalPrice();
            toast.success("Recette supprimée avec succès !");
        } catch (error) {
            console.error("Erreur lors de la suppression de la recette :", error);
            toast.error("Impossible de supprimer la recette du menu.");
        }
    };

    // Update total price of the menu
    const updateTotalPrice = useCallback(() => {
        const total = recipesList.reduce((acc, recipe) => {
            if (recipe?.price) {
                console.log(`Ajout du prix de la recette ${recipe.id || recipe.recipe_id} :`, recipe.price);
                return acc + parseFloat(recipe.price);
            } else {
                console.warn(`Prix non valide pour la recette ${recipe.id || recipe.recipe_id} :`, recipe.price);
                return acc;
            }
        }, 0);
    
        console.log("Prix total calculé :", total);
        setTotalPrice(total.toFixed(2));
    }, [recipesList]);

    // Handle recipe selection in the popup
    const handleRecipeSelect = (selectedRecipes) => {
        const selectedIds = selectedRecipes.map((recipe) => recipe.id);
        console.log("Selected recipes for menu:", selectedRecipes); // Vérifie les recettes sélectionnées
        handleAddRecipesToMenu(selectedIds);
        setOpenMenuPopup(false);
    };

    

    return (
        <div className="menu">
            <h1>{menuInfos?.name || "Menu"}</h1>
            {/* <Typography level="body1">Prix total : {totalPrice}€</Typography>  */}
            <div className="menu-actions">
                <Link to={`/account/menumaker/${id}/shopping-list`}>
                    <Button
                        variant="solid"
                        color="success"
                        startDecorator={<ShoppingBasketIcon />}
                    >
                        Liste de courses
                    </Button>
                </Link>
                <Button
                    variant="soft"
                    color="success"
                    startDecorator={<Add />}
                    onClick={() => setOpenMenuPopup(true)}
                >
                    Ajouter des recettes
                </Button>
            </div>

            <PopupMenu
                open={openMenuPopup}
                onClose={() => setOpenMenuPopup(false)}
                onRecipeSelect={handleRecipeSelect}
                recipes={recipes}
                onAddRecipesToMenu={handleAddRecipesToMenu}
                existingMenuRecipes={recipesList}
            />

            <div className="menu-recipes-list">
                {recipesList.length > 0 && (
                    <>
                        {recipesList.filter(recipe => recipe.partId === 1).length > 0 && (
                            <section>
                                <h2>Entrées</h2>
                                <div className="recipes_list">
                                    {recipesList
                                        .filter(recipe => recipe.partId === 1)
                                        .map(recipe => {
                                            console.log("Recipe sent to RecipeCard:", recipe); // Vérifie les données envoyées à RecipeCard
                                            return (
                                                <RecipeCard
                                                    key={recipe.id}
                                                    recipe={recipe}
                                                    isMenuContext={true}
                                                    onDelete={() => handleDeleteRecipe(recipe.id)}
                                                    menuId={id} // Passer menuId ici
                                                />
                                            );
                                        })}
                                </div>
                            </section>
                        )}
                        {recipesList.filter(recipe => recipe.partId === 2).length > 0 && (
                            <section>
                                <h2>Plats</h2>
                                <div className="recipes_list">
                                    {recipesList
                                        .filter(recipe => recipe.partId === 2)
                                        .map(recipe => {
                                            console.log("Recipe sent to RecipeCard:", recipe); // Vérifie les données envoyées à RecipeCard
                                            return (
                                                <RecipeCard
                                                    key={recipe.id}
                                                    recipe={recipe}
                                                    isMenuContext={true}
                                                    onDelete={() => handleDeleteRecipe(recipe.id)}
                                                    menuId={id} // Passer menuId ici
                                                />
                                            );
                                        })}
                                </div>
                            </section>
                        )}
                        {recipesList.filter(recipe => recipe.partId === 3).length > 0 && (
                            <section>
                                <h2>Desserts</h2>
                                <div className="recipes_list">
                                    {recipesList
                                        .filter(recipe => recipe.partId === 3)
                                        .map(recipe => {
                                            console.log("Recipe sent to RecipeCard:", recipe); // Vérifie les données envoyées à RecipeCard
                                            return (
                                                <RecipeCard
                                                    key={recipe.id}
                                                    recipe={recipe}
                                                    isMenuContext={true}
                                                    onDelete={() => handleDeleteRecipe(recipe.id)}
                                                    menuId={id} // Passer menuId ici
                                                />
                                            );
                                        })}
                                </div>
                            </section>
                        )}
                        {recipesList.filter(recipe => recipe.partId === 4).length > 0 && (
                            <section>
                                <h2>Boissons</h2>
                                <div className="recipes_list">
                                    {recipesList
                                        .filter(recipe => recipe.partId === 4)
                                        .map(recipe => {
                                            console.log("Recipe sent to RecipeCard:", recipe); // Vérifie les données envoyées à RecipeCard
                                            return (
                                                <RecipeCard
                                                    key={recipe.id}
                                                    recipe={recipe}
                                                    isMenuContext={true}
                                                    onDelete={() => handleDeleteRecipe(recipe.id)}
                                                    menuId={id} // Passer menuId ici
                                                />

                                            );
                                        })}
                                </div>
                            </section>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MenuMaker;
