import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import Select from 'react-select';
import { fetchProduct, linkProductsToRecipe, createProduct } from '../../../../api/stockAPI';
import { TextField, Button } from '@mui/material';
import { fetchAllAllergies } from '../../../../api/allergiesAPI';
import toast from 'react-hot-toast';
import './PopupChoose.css';

const PopupChooseProduct = ({ trigger, onSelect, recipeId, recipeName, existingProducts = [] }) => {
    const [productList, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [selectedAllergen, setSelectedAllergen] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: '',
        price: 0,
        totalQuantity: 0,
        category: '',
        label: '',
    });

    // Fetch allergies data
    useEffect(() => {
        const fetchAllergiesData = async () => {
            try {
                const allergies = await fetchAllAllergies();
                setAllergiesOptions(allergies.map(allergy => ({
                    value: allergy.id,
                    label: allergy.name,
                })));
            } catch (error) {
                console.error('Erreur lors de la récupération des allergies :', error);
            }
        };

        fetchAllergiesData();
    }, []);

    // Fetch product list
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const products = await fetchProduct();
                setProductList(products.map(product => ({
                    label: product.name,
                    value: product.id,
                })));
            } catch (error) {
                console.error('Erreur lors de la récupération des produits :', error);
            }
        };

        fetchProducts();
    }, []);

    // Filter out already linked or selected products
    const getFilteredProductList = () => {
        const alreadyLinkedProductIds = existingProducts.map(p => p.productId);
        const selectedProductIds = selectedProducts.map(p => p.product.value);
        return productList.filter(product => !alreadyLinkedProductIds.includes(product.value) && !selectedProductIds.includes(product.value));
    };

    const handleProductSelect = (selectedOptions) => {
        const newSelections = selectedOptions
            .filter(option => !selectedProducts.some(p => p.product.value === option.value))
            .map(option => ({
                product: option,
                quantity: 0, // Default quantity
            }));

        setSelectedProducts(prev => [...prev, ...newSelections]);
    };

    const handleQuantityChange = (index, quantity) => {
        setSelectedProducts(prev => {
            const updated = [...prev];
            updated[index].quantity = quantity;
            return updated;
        });
    };

    const handleSaveNewProduct = async () => {
        if (!newProduct.name.trim() || !newProduct.category) {
            toast.error('Veuillez renseigner le nom et la catégorie.');
            return;
        }

        try {
            const savedProduct = await createProduct({ ...newProduct, allergen: selectedAllergen || null });
            toast.success('Produit créé avec succès !');
            setProductList(prev => [...prev, { label: savedProduct.name, value: savedProduct.id }]);
            setIsAddingNewProduct(false);
            setNewProduct({ name: '', price: 0, totalQuantity: 0, category: '', label: '' });
        } catch (error) {
            console.error('Erreur lors de la création du produit :', error);
            toast.error('Impossible de créer le produit.');
        }
    };

    const handleLinkProducts = async () => {
        const productIds = selectedProducts.map(p => p.product.value);
        const quantities = selectedProducts.map(p => p.quantity);

        if (productIds.length === 0) {
            toast.error('Aucun produit sélectionné à lier.');
            return;
        }

        try {
            await linkProductsToRecipe(recipeId, productIds, quantities);
            toast.success('Produits liés avec succès !');
            onSelect([...existingProducts, ...selectedProducts]);
            setSelectedProducts([]); // Reset selected products
        } catch (error) {
            console.error("Erreur lors de l'association des produits :", error);
            toast.error("Impossible de lier les produits.");
        }
    };

    const categories = [
        { value: 'Fruits et Légumes', label: 'Fruits et Légumes' },
        { value: 'Conserves', label: 'Conserves' },
        { value: 'Epices et Condiments', label: 'Epices et Condiments' },
        { value: 'Céréales et Féculents', label: 'Céréales et Féculents' },
        { value: 'Boissons', label: 'Boissons' },
        { value: 'Huiles et matières grasses', label: 'Huiles et matières grasses' },
        { value: 'Légumineuses', label: 'Légumineuses' },
        { value: 'Sucreries snacks', label: 'Sucreries snacks' },
        { value: 'Oléagineux et Fruits secs', label: 'Oléagineux et Fruits secs' },
        { value: 'Aliments transformés', label: 'Aliments transformés' },
    ];

    const labels = [
        { value: '', label: 'Aucun' },
        { value: 'bio', label: 'Bio' },
        { value: 'local', label: 'Local' },
        { value: 'national', label: 'National' },
    ];

    return (
        <Popup trigger={trigger} modal className="popup-choose-product">
            {close => (
                <div className="popup-content">
                    <h2>Lier des produits à {recipeName}</h2>
                    <div className="selected-products-input">
                        <Select
                            isMulti
                            placeholder="Recherchez des produits"
                            options={getFilteredProductList()}
                            className="basic-multi-select"
                            onChange={handleProductSelect}
                        />
                    </div>

                    {isAddingNewProduct ? (
                    <div className="new-product-fields">
                        <h3>Ajouter un nouveau produit</h3>
                        <TextField
                            className="new-product-fields-textfield"
                            label="Nom du produit"
                            value={newProduct.name}
                            onChange={e => setNewProduct({ ...newProduct, name: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            className="new-product-fields-textfield"
                            label="Prix"
                            type="number"
                            value={newProduct.price}
                            onChange={e => setNewProduct({ ...newProduct, price: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            className="new-product-fields-textfield"
                            label="Quantité en stock"
                            type="number"
                            value={newProduct.totalQuantity}
                            onChange={e => setNewProduct({ ...newProduct, totalQuantity: e.target.value })}
                            fullWidth
                        />
                        <Select
                            className="new-product-fields-select"
                            placeholder="Catégorie"
                            options={categories}
                            onChange={option => setNewProduct({ ...newProduct, category: option.value })}
                        />
                        <Select
                            className="new-product-fields-select"
                            placeholder="Label"
                            options={labels}
                            onChange={option => setNewProduct({ ...newProduct, label: option.value })}
                        />
                        <Select
                            className="new-product-fields-select"
                            placeholder="Allergènes"
                            options={allergiesOptions}
                            onChange={option => setSelectedAllergen(option?.value || null)}
                        />
                        <div className="popup-new-product-actions">
                            <Button className="btn-create" onClick={handleSaveNewProduct}>Créer</Button>
                            <Button className="btn-close" onClick={() => setIsAddingNewProduct(false)}>Fermer</Button>
                        </div>
                    </div>
                ) : (
                    <Button className="btn-add" onClick={() => setIsAddingNewProduct(true)}>
                        Ajouter un nouveau produit
                    </Button>
                )}

                    <div className="selected-products-list">
                        {selectedProducts.map((product, index) => (
                            <div key={index} className="selected-products">
                                <TextField
                                    className="selected-products-quantity"
                                    label={`${product.product.label} - Quantité`}
                                    type="number"
                                    value={product.quantity}
                                    onChange={e => handleQuantityChange(index, e.target.value)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="popup-choose-action">
                        <Button className='btn-ms btn-full' onClick={handleLinkProducts}>Lier les produits</Button>
                        <Button className='btn-ms btn-full' onClick={close}>Fermer</Button>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default PopupChooseProduct;
