import React from 'react';
import { useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../features/cart/cartSlice';
import Pic from '../../assets/recipes/bowl.avif';
import { PiMinus, PiPlus } from 'react-icons/pi';
import { TiDeleteOutline } from 'react-icons/ti';
import toast from 'react-hot-toast';
import './SideCart.css';

function CartItem({ item, isInDeliveryZone, preorderEnabled }) {
    const dispatch = useDispatch();

    // Détermine si l'incrémentation est possible en fonction des règles business
    const canIncrement =
        (!isInDeliveryZone && preorderEnabled) || // Commande groupée : illimitée
        (isInDeliveryZone && preorderEnabled) || // Précommande : illimitée
        (isInDeliveryZone && !preorderEnabled && item.quantity < item.qty_available); // Commande standard : limitée

    // Handler pour incrémenter la quantité d'un article
    const handleIncrement = () => {
        if (canIncrement) {
            dispatch(incrementQuantity(item));
            toast.success(`Quantité de ${item.name} augmentée.`);
        } else {
            toast.error(`Quantité maximale atteinte pour ${item.name}.`);
        }
    };

    // Handler pour décrémenter la quantité d'un article
    const handleDecrement = () => {
        if (item.quantity > 1) {
            dispatch(decrementQuantity(item));
            toast.success(`Quantité de ${item.name} diminuée.`);
        } else {
            handleRemove(); // Supprime l'article si la quantité est égale à 1
        }
    };

    // Handler pour supprimer un article du panier
    const handleRemove = () => {
        dispatch(removeFromCart(item));
        toast.success(`${item.name} a été retiré du panier.`);
    };

    // URL de l'image de l'article
    const imageUrl = item.image
        ? `${process.env.REACT_APP_API_URL}${item.image}`
        : Pic;

    return (
        <div className="cart-item-container">
            <div className="item-img-container">
                <img
                    src={imageUrl}
                    alt={item.name}
                    className="item-img"
                    onError={(e) => {
                        e.target.onerror = null; // Empêche une boucle infinie si l'image de secours échoue
                        e.target.src = Pic; // Utilise l'image par défaut en cas d'échec
                    }}
                />
            </div>
            <div className="item-text">
                <div className="item-details">
                    <span className="item-name">{item.name}</span>
                    <span className="item-price">{item.price.toFixed(2)}€</span>
                </div>
                <div className="item-quantity">
                    <PiMinus
                        className={`item-qty-icon ${item.quantity <= 1 ? 'disabled' : ''}`}
                        onClick={handleDecrement}
                    />
                    <span>{item.quantity}</span>
                    <PiPlus
                        className={`item-qty-icon ${!canIncrement ? 'disabled' : ''}`}
                        onClick={handleIncrement}
                    />
                </div>
                <TiDeleteOutline
                    size={20}
                    className="item-delete-icon"
                    onClick={handleRemove}
                />
            </div>
        </div>
    );
}

export default CartItem;
