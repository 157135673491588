// src/components/orders/GroupOrderConditions.jsx
import React from 'react';
import PropTypes from 'prop-types';

function GroupOrderConditions({ groupOrderConditionsChecked, setGroupOrderConditionsChecked }) {

    return (
        <div className='order-condition-container'>
            <div className="order-condition-input">
                <input
                    id="groupOrderConditions"
                    type="checkbox"
                    className="order-input-checkbox"
                    checked={groupOrderConditionsChecked}
                    onChange={() => setGroupOrderConditionsChecked(!groupOrderConditionsChecked)}
                />
            </div>
            <div className="order-condition-text">
                <span style={{ fontWeight: 600 }}>Vous participez à une commande groupée. </span>
                Vous n'êtes malheureusement pas dans la zone de livraison immédiate.<br />

                Pour profiter des bons petits plats Mamossa partout en France, vous avez choisi de participer à une commande groupée.<br />

                Dès que la commande atteint 250€ ou un nombre de 15 participants,<br /> une commande sera lancé en cuisine, <br /> vous serrez livré à l'adresse de livraison indiquée.<br />
                L'équipe Mamossa vous tiendra au courant par mail de l'évolution de votre commande un temps de préparation de 48 à 72h est généralement nécessaire avant la livraison sur RDV.<br />
                Une fois reçue votre commande peut être conservée entre 48 et 72h au frigo avant d'être consommée. Une congélation est également envisageable<br />
                Pour toute question, n'hésitez pas à contacter +33604534730 ou <a className='link-underline' href="mailto:hello@mamossa.com" target="_blank" rel="noreferrer"> hello@mamossa.com</a> 🔥<br /><br />
                Si jamais vous n'êtes plus disponible, un remboursement vous sera proposé. 
                🔥
            </div>
        </div>
    );
}

GroupOrderConditions.propTypes = {
    groupOrderConditionsChecked: PropTypes.bool.isRequired,
    setGroupOrderConditionsChecked: PropTypes.func.isRequired,
};

export default GroupOrderConditions;
