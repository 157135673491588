import React, { useEffect, useState } from 'react';

// Components
import SideCart from '../../components/side-cart/SideCart';
import Recipes from '../../components/recipes/Recipes';
import PopupWelcome from '../../components/elements/popup/popup-welcome/popupWelcome';

// Styles
import './Home.css';

function Home() {
    const [showPopup, setShowPopup] = useState(false);
    const [openWelcomePopup, setOpenWelcomePopup] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Fonction pour fermer le panier
    const closeCartDropdown = () => {
        console.log("Fermer le panier dans le composant Home");
        // Ajoutez ici toute logique nécessaire
    };

    useEffect(() => {
        const popupShownBefore = sessionStorage.getItem('popupShown');

        if (!popupShownBefore) {
            setOpenWelcomePopup(true);
            sessionStorage.setItem('popupShown', 'true');
        }

        // Mise à jour de la réactivité pour mobile
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Nettoyage à la désinstallation du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="home">
            <div className="recipes">
                <Recipes />
            </div>
            <div className="side-cart">
                {/* Passage correct de closeCartDropdown */}
                <SideCart closeCartDropdown={closeCartDropdown} isMobile={isMobile} />
            </div>
            <PopupWelcome
                open={openWelcomePopup}
                onClose={() => setOpenWelcomePopup(false)}
                message={
                    <div style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                        <p style={{ marginBottom: '1rem' }}>
                            Découvrez la nouvelle fonctionnalité
                            <strong> calcul des calories et Nutri-Score</strong>, en phase de bêta test.
                        </p>
                        <p style={{ marginBottom: '1rem' }}>
                            Aidez-nous à améliorer l'app en partageant vos retours à 
                            <a style={{ color: '#006d26' }} href="mailto:hello@mamossa.com">hello@mamossa.com</a>.
                        </p>
                        <p style={{ marginBottom: '1rem' }}>
                            Service support disponible au +33604534730
                        </p>
                        <p style={{ marginBottom: '1rem' }}>Votre avis compte !</p>
                    </div>
                }
            />
        </div>
    );
}

export default Home;
