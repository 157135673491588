import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

// API
import { fetchCurrentUserInfos } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";

// Components
import ClientNavbar from "../../components/client-dashboard/ClientNavbar";

import "../admin-dashboard/AdminDashboard.css";

function DashboardClient() {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const navigate = useNavigate();

    // States
    const [userInfo, setUserInfo] = useState({});
    const [subscriptionExists, setSubscriptionExists] = useState(false);
    const [subscriptionInfos, setSubscriptionInfos] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data on mount
    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const userInfoData = await fetchCurrentUserInfos();
                setUserInfo(userInfoData);

                const [subscriptionData, participantData] = await Promise.all([
                    fetchSubscription(userInfoData.id),
                    fetchParticipantByUserId(userInfoData.id),
                ]);

                setSubscriptionExists(Boolean(subscriptionData?.active));
                setSubscriptionInfos(subscriptionData?.active ? subscriptionData : null);
                setGroupOrderExist(Boolean(participantData));
            } catch (err) {
                setError("Une erreur est survenue lors de la récupération des données.");
                console.error("Erreur:", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllData();
    }, []);

    return (
        <div className="dashboard-client">
            <ClientNavbar />
            <section className="admin-dashboard-content">
                {isLoading ? (
                    <div>Chargement de vos données...</div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : (
                    // Outlet pour rendre les routes enfants définies dans le routeur principal
                    <Outlet context={{ userInfo, subscriptionExists, subscriptionInfos, groupOrderExist, isTablet }} />
                )}
            </section>
        </div>
    );
}

export default DashboardClient;
