import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Button from '@mui/material/Button';
import "./groupOrders.css"


export default function PopupGroupOrders({ open, handleClose }) {
    const navigate = useNavigate();

    const handleJoinGroupOrder = () => {
        navigate('/home');
    };

    return (
        <div className='popup-group-orders'>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}
                sx={{ overflowX: 'hidden', overflowY: 'scroll' }}
            >
                <ModalContent sx={{ width: 370, height: "90vh", overflowX: 'hidden', overflowY: 'scroll' }}>
                    <h2 id="unstyled-modal-title" className="modal-title">
                        Hors zone de livraison immédiate
                    </h2>
                    <p id="unstyled-modal-description" className="modal-description" style={{ textAlign: "justify" }}>
                        {"Vous n'êtes pas dans la zone de livraison ? Pas de panique. Vous pouvez à présent"}
                        <b>{' rejoindre une commande  groupée'}</b> {'Mamossa'}.{' '}
                        <br /><br />
                        <b>{"Comment cela fonctionne ?"}</b>
                        <br />{`Rejoignez ou crée la commande groupée de votre code Postal, 
dès qu'un montant de 250€ est atteint ou que 15 participants ont commandé, 
Une préparation et livraison groupée est déclenchée.
Vous pouvez profiter des délicieux plats Mamossa livré chez vous. 
Nos plats se conservent facilement 48 à 72h au frigo après livraison, 
voir la date indiquée sur vos produits.`}
                        <br /><br />
                        <b>{`Si jamais vous n'êtes plus disponible, un remboursement vous sera proposé. `}</b>
                        <br /><br />
                        <b>{"Comment serais-je tenu au courant ?"}</b>
                        <br />{`Une interface dédiée est disponible sur votre espace client. 
                        L'équipe Mamossa vous tiendra au courant par mail de l'évolution de votre commande et du jour de votre livraison. 

Notre équipe support reste disponible sur hello@mamossa.com et au 0604534730`}

                    </p>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleJoinGroupOrder}
                        sx={{ marginTop: '16px' }}
                    >
                        Rejoindre une commande groupée
                    </Button>
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={handleClose}
                        sx={{ marginTop: '8px' }}
                    >
                        Fermer
                    </Button>
                </ModalContent>
            </Modal>
        </div>
    );
}

PopupGroupOrders.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);
