import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import {
    setSubscriptionExists,
    resetSubscription,
    isSubscriptionActive
} from "../../features/subscriptions/subscriptionSlice";
import { createInstantPayment } from "../../api/paygreenAPI";
import toast from "react-hot-toast";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import PopupFridge from "../../components/elements/popup/popup-fridge/PopupFridge";

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: "50px",
};

function ClientMobile() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orderError, setOrderError] = useState(null);

    const showButtons = location.pathname === "/account";

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const user = await fetchCurrentUserInfos();
                setUserInfo(user);

                const orders = await fetchCurrentUserOrders();
                if (orders?.length > 0) {
                    const unpaidOrder = orders.find(
                        (order) => order.status === "accepted" && !order.payment_status
                    );
                    const readyOrder = orders.find(
                        (order) =>
                            order.status === "accepted" &&
                            order.payment_status &&
                            order.type === "pickup" // Vérifie si le type est "pickup"
                    );
                    setUnpaidOrdersExist(!!unpaidOrder);
                    setUnpaidOrderNum(unpaidOrder?.order_num || null);
                    setReadyOrderExist(!!readyOrder);
                    setReadyOrderNum(readyOrder?.order_num || null);
                }

                const isParticipant = await fetchParticipantByUserId(user.id);
                setGroupOrderExist(!!isParticipant);

                const subscriptionResponse = await fetchSubscription(user.id);
                if (subscriptionResponse?.data) {
                    const isActive = isSubscriptionActive(subscriptionResponse.data);
                    dispatch(
                        setSubscriptionExists({
                            exists: isActive,
                            subscriptionInfos: { ...subscriptionResponse.data, active: isActive },
                        })
                    );
                } else {
                    dispatch(resetSubscription());
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setOrderError("Impossible de charger les informations.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleRestrictedAccess = (path) => {
        if (!subscriptionExists || !subscriptionInfos?.active) {
            toast.error("Vous devez être abonné pour accéder à cette section.");
            navigate("/account/subscriptions");
        } else {
            navigate(path);
        }
    };

    const initiatePaygreenPayment = async (orderNum) => {
        try {
            const orders = await fetchCurrentUserOrders(userInfo.id);
            const order = orders.find((o) => o.order_num === orderNum);

            if (!order) {
                throw new Error("Commande introuvable.");
            }

            const amount = parseFloat(order.price);
            if (isNaN(amount)) {
                throw new Error("Montant invalide pour la commande.");
            }

            const paymentResult = await createInstantPayment(amount, `${orderNum}`, orderNum);

            if (paymentResult?.instantPayment?.data?.hosted_payment_url) {
                toast.success("Redirection vers la passerelle de paiement...");
                window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
            } else {
                throw new Error("Échec de la création du paiement Paygreen.");
            }
        } catch (error) {
            toast.error("Le paiement a échoué. Veuillez réessayer.");
        }
    };

    const handleButtonClick = async () => {
        try {
            if (unpaidOrdersExist && unpaidOrderNum) {
                // Paiement de la commande impayée
                await initiatePaygreenPayment(unpaidOrderNum);
            } else if (readyOrderExist && readyOrderNum) {
                // Ouvrir le frigo
                setShowPopupFridge(true);
            } else {
                // Rediriger vers la page de commande
                navigate("/"); 
            }
        } catch (error) {
            toast.error("Une erreur est survenue. Veuillez réessayer.");
        }
    };
    

    const canAccessStocks = subscriptionExists && subscriptionInfos?.active && subscriptionInfos?.subscription_type !== "Menu Free";


    if (isLoading) {
        return (
            <div className="loading-screen">
                <h2>Chargement...</h2>
            </div>
        );
    }

    if (orderError) {
        return <div className="error-message">{orderError}</div>;
    }

    const handleFridgeAction = () => {
        setShowPopupFridge(false); // Ferme le pop-up
        setReadyOrderExist(false); // Réinitialise l'état `readyOrderExist` après l'action
    };

    return (
        <>
        {showButtons && (
            <>
                <div className="admin-mobile-header">
                    <h2>Hello {userInfo.firstname || "Utilisateur"} 👋</h2>
                    <Typography level="body-ms" textColor="#f5f5f5" paddingLeft="1rem">
                        Que voulez-vous faire aujourd'hui ?
                    </Typography>
                </div>
                <div className="admin-mobile-actions">
                <Button
                        color="danger"
                        sx={{ width: "270px", height: "50px", marginBottom: "1rem" }}
                        variant="outlined"
                        size="lg"
                        onClick={handleButtonClick}
                    >
                        {unpaidOrdersExist
                            ? "Payer la commande"
                            : readyOrderExist
                            ? "Ouvrir le frigo"
                            : "Commander un repas"}
                    </Button>
    
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        size="lg"
                        onClick={() => handleNavigation("/account/profil")}
                    >
                        Profil
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        size="lg"
                        onClick={() => handleNavigation("/account/my-orders")}
                    >
                        Commandes
                    </Button>
                    {groupOrderExist && (
                        <Button
                            sx={buttonStyle}
                            variant="outlined"
                            size="lg"
                            onClick={() => handleNavigation("/account/orders-tracking")}
                        >
                            Commandes groupées
                        </Button>
                    )}
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        size="lg"
                        onClick={() => handleNavigation("/account/subscriptions")}
                    >
                        Abonnements
                    </Button>
                    {canAccessStocks && (
                        <Button
                            sx={buttonStyle}
                            variant="outlined"
                            size="lg"
                            onClick={() => handleNavigation("/account/stocks")}
                        >
                            Produits
                        </Button>
                    )}
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        size="lg"
                        onClick={() => handleRestrictedAccess("/account/recipes")}
                    >
                        Recettes
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        size="lg"
                        onClick={() => handleRestrictedAccess("/account/menulist")}
                    >
                        Menus
                    </Button>
                    <Button
                        sx={buttonStyle}
                        variant="outlined"
                        onClick={() => navigate("/")} // Redirige vers la route racine
                    >Commander un repas
                    </Button>
                    
                </div>
            </>
        )}

        {showPopupFridge && (
            <PopupFridge
                isOpen={showPopupFridge}
                onConfirm={() => setShowPopupFridge(false)}
                onActionSuccess={handleFridgeAction}
                readyOrderNum={readyOrderNum}
            />
        )}

        <Outlet context={{ userInfo, subscriptionInfos, groupOrderExist }} />
    </>
    );
}

export default ClientMobile;
