import React, { useState, useRef, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Button from "@mui/joy/Button";
import toast from "react-hot-toast";
import { uploadProfilPicture } from "../../api/userAPI";
import ButtonPerso from "../elements/button/Button";
import PopupUpdate from "../elements/popup/popup-update/PopupUpdate";
import ClientAllergies from "./ClientAllergies";
import ClientImpact from "./ClientImpact";
import PopupAllergy from "../elements/popup/popup-allergy/PopupAllergy";
import BackButton from "../elements/backbutton/BackButton";
import Add from "@mui/icons-material/Add";

function ClientInfos() {
  const { userInfo, subscriptionInfos, groupOrderExist } = useOutletContext();

  const [userInfos, setUserInfos] = useState(userInfo || {});
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [isAllergyPopupOpen, setIsAllergyPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);



  // État pour stocker temporairement le fichier sélectionné
  const [selectedFile, setSelectedFile] = useState(null);

  // Ref pour l'input file (caché)
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (userInfo) {
      setUserInfos(userInfo);
      setLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(selectedFile);
      }
    };
  }, [selectedFile]);

  const profilFields = [
    { name: "firstname", label: "Prénom" },
    { name: "lastname", label: "Nom" },
    { name: "email", label: "Email" },
  ];

  // Sélection du fichier via <input type="file" /> caché
  const handleFileSelect = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.log("Aucun fichier sélectionné ou action annulée.");
      return;
    }
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log("Fichier sélectionné :", file);

    // Autorise la re-sélection du même fichier ultérieurement
    e.target.value = "";
  };

  // Ouvre la fenêtre de sélection
  const handleChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Upload du fichier sélectionné
  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error("Aucun fichier sélectionné !");
      console.log("Aucun fichier sélectionné lors du handleFileUpload");
      return;
    }
  
    setIsUploading(true); // Démarre l'upload
  
    try {
      console.log("Début d'upload pour :", selectedFile.name);
      const response = await uploadProfilPicture(userInfos.id, selectedFile);
  
      toast.success("Photo de profil mise à jour !");
      console.log("Upload réussi, nouvelle photo :", response.profilePictureUrl);
  
      // Mettre à jour immédiatement l'URL de l'image dans `userInfos`
      setUserInfos((prev) => ({
        ...prev,
        picture: response.profilePictureUrl,
      }));
    } catch (error) {
      console.error("Erreur lors de l'upload :", error);
      toast.error("Erreur lors de l'upload de la photo de profil");
    } finally {
      setIsUploading(false); // Terminer l'upload
      setSelectedFile(null); // Supprimer l'aperçu temporaire
    }
  };

  const handleUpdateConfirm = (updatedData) => {
    setUserInfos(updatedData);
    setOpenUpdatePopup(false);
  };

  if (loading) {
    return <div style={{ textAlign: "center", marginTop: "20px" }}>Chargement...</div>;
  }
  if (!userInfos.firstname || !userInfos.lastname || !userInfos.email) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        Erreur: Données utilisateur manquantes
      </div>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div
      className="client-infos-container container flex-center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
      }}
    >
      {/* Photo de profil */}
      <div style={{ marginBottom: "20px" }}>
      <img
        src={
          selectedFile && isUploading // Priorité à l'aperçu temporaire si une image est sélectionnée
            ? URL.createObjectURL(selectedFile)
            : userInfos.picture // Ensuite, l'image de profil si elle est disponible
            ? `${process.env.REACT_APP_API_URL}${userInfos.picture}?t=${new Date().getTime()}` // Forcer le rafraîchissement
            : "https://apitest.mamossa.com/public/salade-de-fruits.jpg" // Sinon, affiche le placeholder
        }
        alt="Profil"
        onError={() => setImageError(true)}
        style={{
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />;
      </div>

      {/* Input file caché */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />

      <div style={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
        {/* Bouton qui déclenche la fenêtre de sélection */}
        <Button variant="outlined" color="neutral" onClick={handleChooseFile}>
          Choisir une photo
        </Button>

        {/* Bouton qui envoie le fichier sélectionné */}
        <Button
          variant="solid"
          color="primary"
          onClick={handleFileUpload}
          sx={{
            backgroundColor: "#1C4127",
            color: "#ffffff", // Texte en blanc
            "&:hover": {
              backgroundColor: "#145C34", // Couleur au survol
            },
          }}
        >
          Uploader la photo
        </Button>
      </div>

      {/* Fichier sélectionné */}
      {selectedFile && (
        <div style={{ marginBottom: "10px", fontStyle: "italic", fontSize: "14px" }}>
          Fichier sélectionné : {selectedFile.name}
        </div>
      )}

      {/* Informations utilisateur */}
      <div className="client-infos-perso" style={{ marginTop: "20px", width: "100%" }}>
        <h2 style={{ textAlign: "center", margin: 0 }}>Mes Informations</h2>
        <div className="client-full-name" style={{ marginBottom: "10px", fontSize: "18px" }}>
          {userInfos.firstname} {userInfos.lastname}
        </div>
        <div className="client-mail" style={{ marginBottom: "10px", fontSize: "16px" }}>
          {userInfos.email}
        </div>
        <div style={{ marginBottom: "10px", fontSize: "14px", color: "gray" }}>
          Inscrit depuis le {formatDate(userInfos.createdAt)}
        </div>
        {subscriptionInfos && (
          <div style={{ marginBottom: "10px", fontSize: "14px", color: "gray" }}>
            Statut d'abonnement: {subscriptionInfos.active ? "Actif" : "Inactif"}
          </div>
        )}
        {groupOrderExist && (
          <div style={{ marginBottom: "10px", fontSize: "14px", color: "gray" }}>
            Vous participez à une commande groupée.
          </div>
        )}
      </div>

      <div className="client-infos-action flex-center" style={{ marginTop: "20px" }}>
        <ButtonPerso
          text="Modifier mes informations"
          className="btn-border"
          onClick={() => setOpenUpdatePopup(true)}
        />
        {openUpdatePopup && (
          <PopupUpdate
            open={openUpdatePopup}
            onClose={() => setOpenUpdatePopup(false)}
            initialData={userInfos}
            fields={profilFields}
            title="Modifier le profil"
            onConfirm={handleUpdateConfirm}
          />
        )}
      </div>

      {/* Allergies */}
      <div className="client-infos-allergies" style={{ marginTop: "20px" }}>
        <div className="client-allergies-title">
          <h2 className="flex-center" style={{ marginBottom: 4 }}>
            Mes allergies
          </h2>
          <ClientAllergies />
          <PopupAllergy
            trigger={
              <Button
                color="success"
                variant="plain"
                size="sm"
                sx={{ width: "200px" }}
                startDecorator={<Add />}
              >
                Ajouter une allergie
              </Button>
            }
            userId={userInfos.id}
            onClose={() => setIsAllergyPopupOpen(false)}
          />
        </div>
      </div>

      {/* Impact */}
      <div className="flex-center" style={{ marginTop: "20px" }}>
        <ClientImpact />
      </div>

      {/* Bouton retour */}
      <div style={{ marginTop: "20px" }}>
        <BackButton onClick={() => window.history.back()} />
      </div>
    </div>
  );
}

export default ClientInfos;
